import axios, { AxiosError, AxiosResponse } from 'axios';
import { appSettings } from '../settings';
import { v4 as uuidv4 } from 'uuid';
import { ESessionStore, EUrlParams, HideLoaderApi } from '../data/Constants';
import { IsAppOpenedByExtApp, IsAppOpenedInIframe, UrlHelper, getScopeKey } from '../services';
import { SessionStore } from '../services/SessionStore';
import { authSettings } from './OidcSettings';
import { AppStore } from '../store';
import { setBusy, resetBusy } from '../store/states/BusySlice';

export const getAuthToken = ()=>{
  let token = null;
  if( IsAppOpenedInIframe() ) {
    token = UrlHelper.getSearchParameter( EUrlParams.Token ) || SessionStore.get( ESessionStore.Token );
  }else if( IsAppOpenedByExtApp() ) {
    token = SessionStore.get( ESessionStore.Token );
  }else{
    const sessionKey = `oidc.user:${authSettings.AuthorityUrl}:${authSettings.ClientId}`
    token = SessionStore.get( sessionKey )?.access_token;
  }
  return token;
}


const AxiosClient = axios.create( {
  baseURL: appSettings.ConfiguratorEndpoint,
  timeout: 60000,
  withCredentials: false,
} );
AxiosClient.interceptors.request.use( ( config ) => {
  if( !HideLoaderApi.find( ( url:string )=>config.url?.includes( url ) ) ) {//hide the  loader  for selected URL when calling API
    AppStore.dispatch( setBusy() )
  }
  const token = getAuthToken();
  const scope = getScopeKey();
  config.headers['X-CORRELATION-ID'] = uuidv4();
  config.headers['Context'] = appSettings.ApiKey;
  config.headers['Scope'] = scope;
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  config.headers['Accept'] = 'application/json; text/plain';
  config.headers['X-Requested-With'] = 'CfLM-PHC-Configurator';
  if ( appSettings.STAuth ) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config;
} );


AxiosClient.interceptors.response.use(
  ( response: AxiosResponse ) => {
    AppStore.dispatch( resetBusy() )
    return response;
  },
  ( error: AxiosError ) => {
    AppStore.dispatch( resetBusy() )
    throw error;
  }
);

export default AxiosClient;