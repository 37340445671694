import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tooltip
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { createConfigureRequest, getChanges, getRemovedValues, getSubmodelIdFromSectionId, getNameWithCode, getLanguageCode,GetToken } from '../services';
import { ICollapsibleRowType, ICollapsibleTableType, IConfigurationChange, IConfigurationUpdate, IConfigurationValidationDialogRemovedAssignment, IConfigurationValue, IFeatureOrFamily, IInnerTableType, IModelContext, IModelContextHierarchy, IModelData, IRemovedAssignmentsStoreType, ISection, ISubModelInfo, ITabSectionType, IUpdateStructureType, IVariableAssignment, IVariableValueAssignment } from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { EVariableType, ETabValue } from '../data/Constants';
import DraggableComponent from './DraggableComponent';
import { configure } from '../store/states/ConfigurationSlice';

/**
 * returns a table showing the feature description and the values removed for that feature
 * @param {InnerTableType} param0 The properties required for constructing and displaying the Table
 * @returns {JSX.Element} The Table displaying Feature description and removed values of those features
 */
function InnerTable( {model,showCode}:IInnerTableType ) {
  const {t} = useTranslation();
  if( !model ) {
    return <Box className="d-flex justify-left align-top text-left h-100 p-1rem">
      {t( 'configurationValidationDialog.noItems' )}
    </Box>
  }
  return <TableContainer className="innerTable">
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className="tabCell subTableHeader w-40" align="left">
            {t( 'labels.feature' )}
          </TableCell>
          <TableCell className="tabCell subTableHeader w-40" align="left">
            {t( 'labels.options' )}
          </TableCell>
          <TableCell className="tabCell subTableHeader w-20" align="left">
            {t( 'status.impact' )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="subRowText">
        {model.changes.map( ( change:IConfigurationChange ) => 
          <TableRow key={ change.id }>
            <TableCell id={ `${change.id}.familyDesc` } className= "tabCell subRowText w-40" align="left">
              {getNameWithCode( change as IFeatureOrFamily,showCode )}
            </TableCell>
            <TableCell id={ `${change.id}.featureDesc` }className="tabCell subRowText w-40" align="left">
              {
                change.isRemoved ? '' : change.conflicts?.length && change.conflicts?.map( ( changeDesc:string,idx:number ) => 
                  <Box key={ change.id + '.val' + idx }>{changeDesc}</Box> 
                )
              }
            </TableCell>
            <TableCell className="tabCell subRowText w-20" align="left">
              {t( 'status.discontinued' )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
}

/**
 * returns a table showing the feature description and the values removed for that feature
 * @param {InnerTableType} param0 The properties required for constructing and displaying the Table
 * @returns {JSX.Element} The Table displaying Feature description and removed values of those features
 */
function CollapsibleInnerTable( {model,showCode}:IInnerTableType ) {
  if( !model ) {
    return null;
  }
  return <TableContainer>
    <Table size="small">
      <TableBody className="subRowText">
        {model.changes.map( ( change:IConfigurationChange ) => 
          <TableRow key={ `${model.id}.${change.id}` }>
            <TableCell className="tabCell subRowText w-45" align="left">
              {getNameWithCode( change as IFeatureOrFamily,showCode )}
            </TableCell>
            <TableCell className="tabCell subRowText w-45" align="left">
              {
                change.isRemoved ? '' : change.conflicts?.length && change.conflicts?.map( ( changeDesc:string,idx:number ) => 
                  <Box key={ change.id + '.val' + idx } className={ `d-inline ${idx === 1 ? 'text-bold' : undefined}` }>
                    {changeDesc}
                  </Box> )
              }
            </TableCell>
            <TableCell className="tabCell w-10" align="left"/>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
}

/**
 * Creates Row for a model as required for the collapsible table component
 * @param {ICollapsibleRowType} props various properties that are required for generation of the row
 * @returns {JSX.Element} a row represnting a collapsible table for the given model based on the properties
 */
function CollapsibleTableRow( { model,showCode,expand }: ICollapsibleRowType ) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState( false );
  useEffect( ()=>{
    setOpen( expand )
  },[expand] );

  if( !model ) {
    return null;
  }

  return (
    <>
      <TableRow className="rowText" onClick={ ()=>setOpen( !open ) }>
        <TableCell className="tabCell rowText w-auto" colSpan={ 2 } component="th" scope="row" align="left">
          {getNameWithCode( {name:model.desc,id:model.id,shortSalesText:model.shortSalesText} as IFeatureOrFamily,showCode )}
        </TableCell>
        <TableCell className="tabCell w-10" component="th" scope="row" align="right">
          <Tooltip title={ open ? t( 'tooltip.collapse' ) : t( 'tooltip.expand' ) }>
            <IconButton
              aria-label={ `${t( 'tooltip.collapse' )}/${t( 'tooltip.expand' )}` }
              size="small"
              onClick={ () => setOpen( !open ) }
              className="justify-center"
            >
              {open ? <KeyboardArrowUpIcon className="arrowIcon" /> : <KeyboardArrowDownIcon className="arrowIcon"/>}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow className="collapsibleTableRow">
        <TableCell className="py-0 bottomUnset" colSpan={ 3 }>
          <Collapse in={ open } timeout="auto" unmountOnExit>
            <CollapsibleInnerTable key={ `${model.id}.validationChanges` } model={ model } showCode={ showCode }/>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
 
/**
 * Creates Collapsible table as required and defined by the properties
 * @param {ICollapsibleTableType} param0 various properties that are required for generation of table
 * @returns {JSX.Element} a Collapsible Table representing all the changes required to get a valid configuration
 */
function CollapsibleTable ( {models,showCode}:ICollapsibleTableType ) {
  const {t} = useTranslation();
  const [open,setOpen] = React.useState<boolean>( false );
  if( !models ) {
    return <Box className="d-flex justify-left align-top text-left h-100 p-1rem">
      {t( 'configurationValidationDialog.noChanges' )}
    </Box>;
  }
  return <>
    <TableContainer className="collapsibleTable">
      <Table stickyHeader className="mw-100" aria-label="collapsible table">
        <TableHead>
          <TableRow className="p-0">
            <TableCell className="tabCell subTableHeader w-45" align="left">
              {t( 'labels.feature' )}
            </TableCell>
            <TableCell className="tabCell subTableHeader w-45" align="left">
              {t( 'labels.changes' )}
            </TableCell>
            <TableCell className="tabCell w-10" variant="head" align="right">
              <Tooltip title={ open ? t( 'tooltip.collapseAll' ) : t( 'tooltip.expandAll' ) }>
                <IconButton
                  aria-label={ `${t( 'tooltip.collapseAll' )}/${t( 'tooltip.expandAll' )}` }
                  size="medium"
                  onClick={ () => setOpen( !open ) }
                  className="justify-center"
                >
                  {open ? <KeyboardArrowUpIcon className="arrowIcon" /> : <KeyboardArrowDownIcon className="arrowIcon"/>}
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {models?.map( ( model:IConfigurationUpdate ) => 
            <CollapsibleTableRow key={ model.id } model={ model } showCode={ showCode } expand={ open }/>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

/**
 * To get the values that are to be shown in badges of the tabs
 * @param {[IConfigurationUpdate[], IConfigurationUpdate[]]} tabSectionsData The data being shown in the tab sections
 * @returns {[number,number]} The values that should be shown in badges of both the tabs as an array
 */
const getBadgeValues = ( tabSectionsData:[IConfigurationUpdate[] | null, IConfigurationUpdate | null] )=>
  [
    tabSectionsData && tabSectionsData.length && tabSectionsData[0] && tabSectionsData[0].reduce( ( c,update ) =>
      c + update.changes.length,
    0 ) || 0 ,
    tabSectionsData && tabSectionsData.length && tabSectionsData[1]?.changes.length || 0
  ]

/**
 * To create the Tab Selection window for the Component
 * @param {ITabSectionType} param0 The props required for the Component
 * @returns {JSX.Element} the TabSection for the component
 */
function TabSection( {tabSectionsData,showCode}:ITabSectionType ) {
  const {t} = useTranslation();
  const [TabValue, setTabValue] = React.useState<string|null>( null );
  const [b1,b2] = getBadgeValues( tabSectionsData );

  /**
   * To set the TabValue onChange of the Tab 
   * @param {React.SyntheticEvent} event the event onChange of the Tab
   * @param {string} newValue The value to be assigned to the TabValue
   * @returns {void} it sets the TabValue
   */
  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  return <TabContext value={ TabValue || ETabValue.Attention }>
    <Box className="tabSection-root">
      <TabList onChange={ handleChange } aria-label={ `${t( 'status.attention' )}/${t( 'status.information' )}` }>
        <Tab className="tab-button"  
          label={ <Tooltip title={ 'Attention' + ( b1 ? `(${b1})` : '' ) }><>
            {t( 'status.attention' )}
            <Avatar id="AttentionCount" className={ `tab-badge ${TabValue !== ETabValue.Information ? 'selected' : undefined}` }>
              {b1 > 99 ? '99+' : b1}
            </Avatar>
          </></Tooltip> } value={ ETabValue.Attention }
        />
        <Tab className="tab-button" 
          label={ <Tooltip title={ 'Information' + ( b2 ? `(${b2})` : '' ) }><>
            {t( 'status.information' )}
            <Avatar id="InformationCount" className={ `tab-badge ${TabValue === ETabValue.Information ? 'selected' : undefined}` }>
              {b2 > 99 ? '99+' : b2}
            </Avatar>
          </></Tooltip> } 
          value={ ETabValue.Information }
        />
      </TabList>
    </Box>
    <TabPanel className="tabSection-panel" value={ ETabValue.Attention }>
      <CollapsibleTable models={ tabSectionsData && tabSectionsData.length && tabSectionsData[0] } showCode={ showCode } />
    </TabPanel>
    <TabPanel className="tabSection-panel" value={ ETabValue.Information }>
      <InnerTable model={ tabSectionsData && tabSectionsData.length && tabSectionsData[1] } showCode={ showCode } />
    </TabPanel>
  </TabContext>
}

/**
 * To create the initial skeleton for the updates structure based on the model context
 * @param {string} desc the path to the model
 * @param {IUpdateStructureType} updates the reference of the structure to be initialized
 * @param {IModelContextHierarchy|undefined} node the current node
 * @param {IModelContext} modelContext The context of the model
 * @returns {void} it sets the key as model id and value as required for the collapsible table component
 */
function initUpdates( desc:string,updates:IUpdateStructureType, node?:IModelContextHierarchy|undefined,modelContext?:IModelContext ) {
  if( !node || !modelContext ) {
    return;
  }
  for( const child of node?.children || [] ) {
    const name = modelContext.subModels.find( ( subModel:ISubModelInfo ) => subModel.id === child.id )?.name;
    const $desc = `${desc} > ${name}`;
    updates[child.id] = {
      id:child.id,
      name: name,
      desc: $desc,
      changes:[]
    }
    initUpdates( $desc, updates, child ,modelContext );
  }
}

/**
 * To add an update to the updates
 * @param {IUpdateStructureType} updates the structure to be updated
 * @param {IConfigurationValidationDialogRemovedAssignment} variable is the variable in the removed assignments
 * @param {IModelData} model the model the variable belongs to
 * @param {boolean} removedFamily the current family removal state for that variable
 * @param {boolean} showCode user preference
 * @param {IConfigurationValue[]} assigned the values available for variable currently
 * @returns {void}
 */
function addUpdated( updates:IUpdateStructureType,variable:IConfigurationValidationDialogRemovedAssignment, model:IModelData,removedFamily?:boolean,showCode?:boolean,assigned?:IConfigurationValue[] ) {
  if( !variable.values.length ) {
    return;
  }
  const {modelId,modelName,modelDesc} = model;
  if( !( modelId in updates ) ) {
    updates[modelId] = {id:modelId,name:modelName,desc:modelDesc,changes:[]};
  }
  let changeType = 0, assignedValues:IConfigurationValue[] = [];
  if( !variable.allowMultipleAssignments && assigned ) {
    assignedValues = assigned.filter( ( v:IConfigurationValue )=>v.state.isAssigned )
    if( assignedValues.length ) {
      changeType = 2;
    }else{
      changeType = 1;
    }
  }
  updates[modelId]['changes'].push( {
    id:variable.id,
    name:variable.name,
    isRemoved: !!removedFamily,
    conflicts : getChanges( variable.values, variable.allowMultipleAssignments , changeType , showCode , assignedValues ) || []
  } );
}

/**
 * To add a discontinued update to the deprecated structure
 * @param {IUpdateStructureType} deprecated the structure to be updated
 * @param {IConfigurationValidationDialogRemovedAssignment} variable is the variable in the removed assignments
 * @param {boolean} removedFamily the current family removal state for that variable
 * @param {boolean} showCode user preference
 * @returns {void}
 */
function addRemoved( deprecated:IUpdateStructureType,variable:IConfigurationValidationDialogRemovedAssignment,removedFamily?:boolean,showCode?:boolean ) {
  if( !variable.values.length ) {
    return;
  }
  deprecated['undefined']['changes'].push( {
    id:variable.id,
    name:variable.name,
    isRemoved: !!removedFamily,
    conflicts : getRemovedValues( variable.values , variable.allowMultipleAssignments , showCode )
  } );
}

/**
 * To generate the data required for proper structuring of the table for that section
 * @param {IModelData} model Model for the section
 * @param {IUpdateStructureType} updates structure to store changes
 * @param {IUpdateStructureType} deprecated structure to store discontinued changes
 * @param {IRemovedAssignmentsStoreType} removedAssignments removed assignments in configuration
 * @param {boolean} showCode user preference to show or hide codes
 * @param {ISection} section the section for which to get updates
 * @returns {void} 
 */
function getUpdates( model:IModelData,updates:IUpdateStructureType,deprecated:IUpdateStructureType,removedAssignments:IRemovedAssignmentsStoreType,showCode:boolean,section?:ISection ) {
  let {modelId,modelName,modelDesc} = model;
  const $modelId = getSubmodelIdFromSectionId( section?.id || '' );
  const variables = section?.variables || [];
  const sections = section?.sections || [];
  if( $modelId ) {
    modelId = $modelId;
    modelName = section?.name;
    modelDesc = `${modelDesc} > ${modelName}`;
  }
  for( const variable of variables ) {
    if( variable.id in removedAssignments ) {
      const vt = variable.variableType;
      if( vt === EVariableType.Date || vt === EVariableType.String || vt === EVariableType.Number ) {
        addUpdated( updates,removedAssignments[variable.id],{modelId,modelName,modelDesc},false,false,variable.values );
        delete removedAssignments[variable.id];
        continue;
      }
      const UChanges:IConfigurationValidationDialogRemovedAssignment = {
        id:variable.id,
        name:variable.name,
        values: removedAssignments[variable.id].values.filter( ( value:IVariableValueAssignment )=>
          variable.values.find( ( v:IConfigurationValue )=>v.value === value.value ) 
        ),
        allowMultipleAssignments:variable.allowMultipleAssignments
      },DChanges:IConfigurationValidationDialogRemovedAssignment = {
        id:variable.id,
        name:variable.name,
        values: removedAssignments[variable.id].values.filter( ( value:IVariableValueAssignment )=> 
          !variable.values.find( ( v:IConfigurationValue )=>v.value === value.value ) 
        ),
        allowMultipleAssignments:variable.allowMultipleAssignments
      };
      addUpdated( updates,UChanges,{modelId,modelName,modelDesc},false,showCode,variable.values );
      addRemoved( deprecated,DChanges,false,showCode );
      delete removedAssignments[variable.id];
    }
  }
  for( const sec of sections ) {
    getUpdates( {modelId,modelName,modelDesc},updates,deprecated,removedAssignments,showCode,sec );
  }
}


//This method returns the removed assignments in the IRemovedAssignmentsStoreType structure
function updateRemovedAssignments ( removedVariableAssignments:IVariableAssignment[], removedAssignments:IRemovedAssignmentsStoreType ):IRemovedAssignmentsStoreType {
  removedVariableAssignments?.forEach( ( assignment:IVariableAssignment ) => {
    const id:string = assignment?.variable?.id || '';
    if( !( id in removedAssignments ) ) {
      removedAssignments[id] = {
        id:id,
        name:assignment.variable.name,
        values:[],
        allowMultipleAssignments:assignment.variable.allowMultipleAssignments
      };
    }
    removedAssignments[id].values.push( assignment?.value );
  } );
  return removedAssignments;
}

/**
 * A container to render the changes required to get to a valid state
 * @returns {JSX.Element} The configuration updates component displaying the changes
 */
export const ConfigurationValidationDialog = () => {
  const {token} = GetToken();
  if( !token ) {
    return null;
  }
  const {t} = useTranslation();
  const [tabSectionsData, setTabSectionsData] = React.useState<[IConfigurationUpdate[] | null, IConfigurationUpdate | null]>( [null, null] );
  

  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const showCode = userSettings.showCode
  const dispatch = useDispatch<AppDispatch>();
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  const noDisplay = !( configuration && configuration.data && configuration.isInvalid );
  
  useEffect( ()=>{
    //generating the removed assignments by grouping all the removed assignments of a variable under its id
    if ( noDisplay ) {
      return;
    }

    removedAssignments = updateRemovedAssignments( removedVariableAssignments,removedAssignments )

    //generating the structure for updates
    initUpdates( rootModel.name,updates,modelContext.hierarchy,modelContext );

    //getting updates by iterating over all the sections in the model
    for( const section of rootSections ) {
      getUpdates( {modelId:rootModel.id,modelName:rootModel.name,modelDesc:rootModel.name},updates,deprecated,removedAssignments,showCode,section );
    }
    //adding updates for variables absent in the current version of the model
    for( const assignment in removedAssignments ) {
      const variable = removedAssignments[assignment];
      addRemoved( deprecated,variable,true, showCode && variable.values.some( ( value:IVariableValueAssignment )=>value.name !== value.value ) );
    }
    //filtering out models with no changes to display
    setTabSectionsData( [
      Object.values( updates ).filter( ( update:IConfigurationUpdate )=>update.changes.length ),
      Object.values( deprecated ).filter( ( update:IConfigurationUpdate )=>update.changes.length )[0]
    ] );
  },[configuration.isInvalid] );

  if ( noDisplay ) {
    return null;
  }
  const rootSections = configuration.data?.sections || [];
  const modelContext = configuration.savedConfiguration.modelContext;
  const rootModel = configuration.savedConfiguration.modelContext.rootModel;
  const removedVariableAssignments = configuration?.data?.removedAssignments?.variableAssignments || [];
  let removedAssignments:IRemovedAssignmentsStoreType = {};
  const updates:IUpdateStructureType = {
    [rootModel.id]:{
      id:rootModel.id,
      name:rootModel.name,
      desc:rootModel.name,
      changes:[]
    }
  };
  const deprecated:IUpdateStructureType = {
    'undefined':{
      id:'undefined',
      name:'undefined',
      desc:'undefined',
      changes:[]
    }
  } ;

  return <>
    <Dialog className="configurationValidationDialog-root" open PaperProps={ {
      className : 'configurationValidationDialog-paper'
    } } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle className="header cursor-move" variant="h6">{t( 'configurationValidationDialog.title' )}</DialogTitle>
      <DialogContent className="w-100 p-1rem py-0" >
        <TabSection tabSectionsData={ tabSectionsData } showCode={ showCode } />
      </DialogContent>
      <DialogActions className="footer">
        <Button onClick={ ()=>{
          const request = createConfigureRequest( rootModel.id,configuration.configurationId,configuration.assignments );
          dispatch( configure( { request: request,token: token, language: getLanguageCode() } ) );
        } } 
        className="text-capitalize" autoFocus color="primary" variant="contained"
        >
          {t( 'button.continue' )}
        </Button>
      </DialogActions>
    </Dialog>
  </>
}