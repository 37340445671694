import { getPackagePath, getProductId, getApiUrl } from '../services';
import { ApiUrl, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';

export const ProductApi = {
  productCatalog: ( action: { token: string, page:number, limit:number, productSearch?: string,agCodes?:string[], channelCodes?: string[] } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.productSearch ? `${EUrlParams.ProductSearch}=` + action.productSearch + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/product/v1/catalog?${queryParams}`,
        {
          agCodes: action.agCodes ? action.agCodes : [],
          channels: action.channelCodes ? action.channelCodes : []
        },
        getHeaders( action.token ) 
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          productCatalog: response.data,
          pageDetails:{page: action.page,totalRecords: total,limit: action.limit,agCodes:action.agCodes, channelCodes: action.channelCodes}
        }
      } ).catch( ( ) => null )
  },

  myConfigurations: ( action: { token: string, page:number, limit:number, productSearch?: string,applicationIds?:string[], channelCodes: string[] } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.productSearch ? `${EUrlParams.ProductSearch}=` + action.productSearch + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/user/v1/configurations/read?${queryParams}`,
        {
          applicationIds: action.applicationIds ? action.applicationIds.filter( x=>x !== 'Application' ) : [],
          channels: action.channelCodes ? action.channelCodes : []
        },
        getHeaders( action.token )
      ).then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          myConfigurations: response.data === '' ? [] : response.data,
          pageDetails:{page: action.page,totalRecords: total,limit: action.limit,applicationIds: action.applicationIds, channelCodes: action.channelCodes}
        }
      } ).catch( ( ) => null )
  },

  getProductImages: ( action: { token: string, modelId: string } ) => {
    return AxiosClient
      .get( getApiUrl( ApiUrl.ImagesApi, { packagePath:getPackagePath( getProductId() ),productModel: action.modelId } ),
        getHeaders( action.token )
      ).then( ( response ) => {
        return {
          productImages: response.data,
          modelId: action.modelId
        }
      } ).catch( ( ) => {
        return {
          modelId: action.modelId
        }
      } )
  }, 

  productFilter: ( action : { token: string } )=>{
    return AxiosClient
      .get( '/product/v1/filter/read',
        getHeaders( action.token )
      )
      .then( ( response ) => {
        return {
          productHierarchy: response.data
        }
      } ).catch( ( ) => [] )
  },

  channelHierarchy: ( action : { token: string } )=>{
    return AxiosClient
      .get( '/user/v1/configurations/category/read',
        getHeaders( action.token )
      )
      .then( ( response ) => {
        return {
          channelHierarchy: response.data
        }
      } ).catch( ( ) => null )
  },

  //Get Property Associations from DB using DIS API
  getPropertyAssociations: ( payload: {token:string, package: string, parentId: string, childId: string, propertyDefiniton: [] } ) =>{
    const req = {
      package: payload.package, 
      parentId: payload.parentId, 
      childId: payload.childId
    }
    return AxiosClient
      .post( '/product/v1/propertyassociation/read', req, getHeaders( payload.token )
      )
      .then( ( response ) => {
        return {
          propertiesAssociation: response.data.properties,
          propertyDefinition: response.data.definitions != null ? response.data.definitions : payload.propertyDefiniton,
          modelId: payload.childId
        }
      } ).catch( () => {
        return {
          data: [{
            modelId: payload.parentId, 
            subModelId: payload.childId
          }]
        }
      } )
  },
}