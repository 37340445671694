import { appSettings } from "../settings";

export const requestHeader: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'Philips_Ace_Configurator',
};

//Get the api headers 
//Returning headers only with Accept-Language if STAuth is true
//Token will be set from AxiosClient.ts when STAuth is true
export const getHeaders = ( token: string, lang?:string ) => {
  if(appSettings.STAuth){
    return lang ? {headers: { 'Accept-Language':lang }} : undefined;
  }

  return {headers: {'Authorization': `Bearer ${token}`}, ...(lang && {'Accept-Language':lang})}
}