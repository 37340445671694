import { getPackagePath, getProductId } from '../services';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const PriceApi = {
  price: ( action: { token: string, countryCode: string, currencyCode: string, modelId?: string } ) => {
    const productModel = action.modelId ? action.modelId : getProductId();
    return AxiosClient
      .get( `/product/v1/price/read?packagePath=${getPackagePath( getProductId() )}&country=${action.countryCode}&currency=${action.currencyCode}&productModel=${productModel}`,
       getHeaders( action.token))
      .then( ( response ) => {
        return {
          price: response.data
        }
      } ).catch( () => {
        return null
      } )
  },

  currency: ( action: { token: string, countryCode: string } ) => {
    return AxiosClient
      .get( `/product/v1/currency/read?packagePath=${getPackagePath( getProductId() )}&country=${action.countryCode}`,getHeaders( action.token) )
      .then( ( response ) => {
        return {
          currency: response.data
        }
      } ).catch( () => {
        return null
      } )
  },

  priceListMap: ( action: { token: string } ) => {
    return AxiosClient
      .get( `/product/v1/priceList/read?packagePath=${getPackagePath( getProductId() )}`,getHeaders( action.token) )
      .then( ( response ) => {
        return response.data.priceMaps
      } ).catch( () => {
        return null
      } )
  }
}