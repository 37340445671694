import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppStore } from '../store';
import { alpha, styled } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem,TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { tableCellClasses } from '@mui/material/TableCell';
import { Price } from './InputComponents/Price';
import { Table, TableBody, TableCell, TableRow, TableHead, Button, Autocomplete,TextField,InputAdornment, Tooltip } from '@mui/material'
import { getFormattedPrice, getNameWithCode,isSubModelAssigned,getSubModelsFromSectionId, calculatePrice,getValueFromVariable,getModelPathFromSection,getAssignedValue,getSymbol, isSubModel } from '../services';
import {getSubmodelCountVariableMap} from './ConfigurationSummaryPanel';
import SearchIcon from '@mui/icons-material/Search';
import { IValue,IConfigurationVariablePrice,IDetailedSummary, ISection,ICreateSectionProps,IConfigurationVariable,IFeatureOrFamily, ICreateDetailedSummaryTableProps, IDetailedSummaryProps,ISectionPrice, IFeatureFlags, IExtendedConfigureResponse, IApplicationSettings } from '../../types';
import { EVariableType } from '../data/Constants';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
import classNames from 'classnames';

interface RenderTree {
  id: string;
  name: string;
  children?: readonly RenderTree[];
}

const StyledTreeItem = styled( ( props: TreeItemProps ) => 
  <TreeItem { ...props } />
) ( ( { theme } ) => ( {
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha( theme.palette.text.primary, 0.4 )}`,
  },
} ) );

const StyledTableCell = styled( TableCell )( ( { theme } ) => ( {
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1d93cf',
    color: theme.palette.common.white,
    textAlign: 'left',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    borderBottom : '0'
  },
  [`& .${tableCellClasses.root}`]: {
    borderBottom: 'none'
  } 
} ) );

const checkOptional = function ( v:IConfigurationVariablePrice,val:IValue,t:( arg:string,arg2?:object ) => string ) {
  if( v.variableType === EVariableType.Number || v.variableType === EVariableType.String ) {
    return v.isMarkedOptional ? t( 'labels.yes' ) : '';
  } else{
    return val.isMarkedOptional ? t( 'labels.yes' ) : '' ;
  }
}
const isDuplicateSubmodelSection = ( obj:IDetailedSummary|ISection, row: ISection, configuration: IExtendedConfigureResponse , submodels:string[] ) =>{
  const submodelIds : string[] = [];
  if( obj.children && obj.children.length > 0 ) {
    obj.children.forEach( ( sm )=>{
      submodelIds.push( sm.id )
    } );
  } 
  if( row.name === 'Scope' || 
        !!submodelIds.find( ( id )=> id === row.id ) || 
        isSubModel( row ) && 
        isSubModelAssigned( configuration, submodels ) ) {
    return true;
  }
  return false;
}

const createVariableRow = ( obj:IDetailedSummary|ISection,featureFlags:IFeatureFlags,t:( arg:string,arg2?:object ) => string, configuration: IExtendedConfigureResponse , submodels:string[] ,showCode:boolean, applicationSettings: IApplicationSettings ):any=>{  
  return obj.sections.map( ( row ) => {
    if ( isDuplicateSubmodelSection( obj, row, configuration, submodels ) ) {
      return '';
    }
    const showPrice = isFeatureVisible( featureFlags.ListPrice );
    const showOptional = isFeatureVisible( featureFlags.Optional );
    const variablesData = row.variables.map( ( v )=> {
      const values = getValueFromVariable( v, {showCode: showCode, language: null}, 'detailedSummaryPanel' );
      return values.map( ( val, index )=> {
        const variableName = getNameWithCode( v,showCode,'detailedSummaryPanel' );
        const optionName = v.variableType !== EVariableType.Number ? val.name : ''
        return <TableRow key={ 'row' + index } >
          <TableCell component="th" scope="row">
            <Tooltip title={ variableName }>
              <span className= { classNames( {['table-cell-overflow']: !applicationSettings?.pageSize.isExtraSmall, ['table-cell-overflow-mobile']: applicationSettings?.pageSize.isExtraSmall}) } >{ variableName }</span>
            </Tooltip>
          </TableCell>
          <TableCell component="th" scope="row">
            <Tooltip title={ optionName }>
              <span className={ classNames( {['table-cell-overflow']: !applicationSettings?.pageSize.isExtraSmall, ['table-cell-overflow-mobile']: applicationSettings?.pageSize.isExtraSmall}) }>{ optionName }</span>
            </Tooltip>
          </TableCell>
          <TableCell component="th" scope="row" align="right">{v.variableType === EVariableType.Number ? val.name : ''}</TableCell>
          {showOptional && <TableCell component="th" scope="row" >{ checkOptional( v,val,t ) }</TableCell>}
          {showPrice && <TableCell component="th" scope="row" align="right">
            <Price price={ val.price } />
          </TableCell>}
        </TableRow>
      }
      )
    } )  
    const subSectionSubmodels = getSubModelsFromSectionId( row.id );
    const subSectionsData = row.sections.length > 0 ? createVariableRow( row,featureFlags,t, configuration, subSectionSubmodels, showCode,  applicationSettings) : '';
    return [...variablesData,...subSectionsData];
  } ) 
}

const createDetailedSummaryTable = ( props: ICreateDetailedSummaryTableProps ) => {
  const {showCode, obj, index, submodels, configuration, applicationSettings,featureFlags, getCountVariable, t} = props;
  const showPrice = isFeatureVisible( featureFlags.ListPrice );
  const showOptional = isFeatureVisible( featureFlags.Optional );
  if( isSubModelAssigned( configuration,submodels ) ) {
    return <>
      {!obj.noValueFound && <TableRow
        key={ 'row' + obj.id + index }
        className="sectionRow"
      >
        {/* For product there is no Feature and Option hence column span 2 */}        
        <TableCell component="th" scope="row" colSpan={ 2 } >
          <b>{ getNameWithCode( {id:obj.productId, name: obj.name},showCode,'detailedSummaryPanel' ) }</b>
        </TableCell>

        {/* Qty */}
        <TableCell component="th" scope="row" align="right" >
          <b>{ getAssignedValue( getCountVariable && getCountVariable( obj ) )?.value}</b>
        </TableCell>

        {/* For product there is no Optional hence empty table cell*/}
        {showOptional && <TableCell component="th" scope="row" />}
        
        {/* ListPrice */}   
        {showPrice && <TableCell component="th" scope="row" colSpan={ 1 } align="right" >
          <span className={ classNames( 'content',{ ['invalidCurrency']: !isValidCurrency( applicationSettings.currency?.currencyCode )} ) }>
            <b> {applicationSettings.currency?.currencyCode && getSymbol( applicationSettings.currency?.countryCode, applicationSettings.currency?.currencyCode ) } </b>
          </span>
          <b>{applicationSettings.currency.currencyCode && getFormattedPrice( obj.basePrice ? obj.basePrice + obj.price : obj.price )}</b>
        </TableCell>}
      </TableRow> }

      {showPrice && obj.basePrice ? <TableRow key={ 'row' + index } >
        <TableCell component="th" scope="row" colSpan={ 3 } >
          { getNameWithCode( {id:obj.productId, name: obj.name},showCode,'detailedSummaryPanel' ) }
        </TableCell>
        <TableCell component="th" scope="row" colSpan={ 2 } align="right" >
          <span>
            <b> {applicationSettings.currency?.currencyCode && getSymbol( applicationSettings.currency?.countryCode, applicationSettings.currency?.currencyCode ) } </b>
          </span>
          {applicationSettings.currency.currencyCode && getFormattedPrice( obj.basePrice )}
        </TableCell>
      </TableRow> : null }
      {createVariableRow( obj,featureFlags,t, configuration, submodels ,showCode, applicationSettings )}
      { obj.children.map( ( ch: IDetailedSummary,index1: number )=>{             
        return <CreateSection obj={ ch } index={ index1 + 1 } key={ ch.id + index1 }/>
      } )}
    </>
  } else{
    return null;
  }
}

const CreateSection = ( { obj,index,displayMessage,value,showCode }:ICreateSectionProps )=>{
  const { t } = useTranslation();
  const submodels = getSubModelsFromSectionId( obj.id );
  const configuration = AppStore.getState().configuration;
  const submodelCountVariableMap = getSubmodelCountVariableMap();
  const applicationSettings = AppStore.getState().applicationSettings;
  const featureFlags = AppStore.getState().claimsData.featureFlags;
  const getCountVariable = ( modelSection:ISection|IDetailedSummary )=>{
    const modelId = getModelPathFromSection( modelSection );
    return modelId ? submodelCountVariableMap[modelId] : null;
  }
  if( displayMessage ) {
    document.getElementById( 'configurationTable' )!.style.height = '100%'
  } else{
    document.getElementById( 'configurationTable' )?.style.removeProperty( 'height' )
  }
  return (
    displayMessage ? 
      <TableRow key={ 'row' + obj.id + index } >
        <TableCell component="th" scope="row" colSpan={ 6 } sx={ {textAlign:'center',verticalAlign:'middle !important'} }>
          No results found for <b>&apos;{value}&apos;</b>
        </TableCell>
      </TableRow> : 
      createDetailedSummaryTable( {obj, index, submodels, configuration, applicationSettings, featureFlags, getCountVariable, t,showCode} )
  )
}

// Function to get the data of selected model
const getSelectedData = ( obj: IDetailedSummary , selectedModel: string ):IDetailedSummary|null =>{
  if( obj.id === selectedModel ) { 
    return obj; 
  }
  for( const i in obj.children ) {
    const temp:IDetailedSummary|null = getSelectedData( obj.children[i], selectedModel );
    if( temp ) {
      return temp;
    } 
  }
  return null;
};

// Function to check if the value is present in the model
const searchModelForValue = ( newVal:string, modelData:IDetailedSummary|ISection, setDisplayMessage:( value:boolean ) => void ,showCode:boolean ) =>{
  let noValueFound = true;
  modelData.sections.forEach( ( section:ISection ) => {
    if( section.name !== 'Scope' ) {
      let flag: boolean|undefined;
      section.variables = section.variables.filter( ( variable:IConfigurationVariable ) => {
        flag = getNameWithCode( variable,showCode,'detailedSummaryPanel' ).toLowerCase().includes( newVal.toLowerCase() );
        if( flag ) {
          return flag;
        } else{
          variable.values = variable.values.filter( ( value ) => {
            flag = getNameWithCode( value as IFeatureOrFamily,showCode,'detailedSummaryPanel' ).toLowerCase().includes( newVal.toLowerCase() ) || value.shortSalesText?.toLowerCase().includes( newVal.toLowerCase() );
            return flag;            
          } )
          return flag;
        }
      } )  
      if( section.sections ) {
        searchModelForValue( newVal,section,setDisplayMessage,showCode );
      }
      if( section.variables.length > 0 ) {
        noValueFound = false;
        setDisplayMessage( false );
      }          
    }
  } );
  modelData.noValueFound = noValueFound;
  if( modelData.children?.length > 0 ) {
    modelData.children.forEach( modelDataChildren => {
      searchModelForValue( newVal,modelDataChildren,setDisplayMessage,showCode )
    } )
  } 
}

// Function to handle the search in left pane
const handleDetailedSearch = ( newVal:string|null, setSummaryData:( arg:IDetailedSummary|null ) => void , propData:IDetailedSummary, selected: string, setDisplayMessage:( value:boolean ) => void, showCode:boolean ) => {  
  if( !newVal ) {
    setDisplayMessage( false );
    setSummaryData( getSelectedData( propData,selected ) )
  } else {
    setDisplayMessage( true );
    const summaryDataNew = JSON.parse( JSON.stringify( getSelectedData( propData,selected ) ) );
    searchModelForValue( newVal,summaryDataNew,setDisplayMessage,showCode );
    setSummaryData( summaryDataNew ) ;
  }
}

// Function to render the tree with dynamic input
const renderTree = ( nodes: RenderTree, configuration: IExtendedConfigureResponse, expandNodes: string[] ) => {
  const submodels = getSubModelsFromSectionId( nodes.id );
  if( isSubModelAssigned( configuration,submodels ) ) {
    expandNodes.push( nodes.id );
    return <StyledTreeItem key={ nodes.id } itemId={ nodes.id } label={ nodes.name }>
      {Array.isArray( nodes.children )
        ? nodes.children.map( ( node ) => renderTree( node, configuration, expandNodes ) )
        : null}
    </StyledTreeItem>
  } 
  return null;
};

//Tree Parent icon
const AddCircle = () =>{
  return <AddCircleIcon className="defaultExpandIcon zoom80"/>
}
  
//Tree Children icon
const RemoveCircle = () => {
  return <RemoveCircleIcon className="defaultCollapseIcon zoom80" />
}
  
//tree last level icon
const RemoveCircleEnd = () => {
  return <RemoveCircleIcon className="defaultEndIcon zoom80" />
}


export const DetailedSummaryDialog = ( props: IDetailedSummaryProps ) => {
  const propData = props.detailedSummaryData ;
  const [expanded, setExpanded] = React.useState<string[]>( [] );
  const [selected, setSelected] = React.useState<string>( propData.id );
  const [searchValue, setSearchValue] = React.useState( '' );
  const [displayMessage, setDisplayMessage] = React.useState( false );
  const [summaryData, setSummaryData] = React.useState( JSON.parse( JSON.stringify( propData ) ) );
  const applicationSettings = AppStore.getState().applicationSettings;
  const configuration = AppStore.getState().configuration;
  const featureFlags = AppStore.getState().claimsData.featureFlags;
  const { t } = useTranslation();
  const showPrice = isFeatureVisible( featureFlags.ListPrice );
  const showOptional = isFeatureVisible( featureFlags.Optional );
  const showCode = AppStore.getState().userSettings.showCode;
  const expandNodes:string[] = [];

  const handleToggle = ( _event: React.SyntheticEvent, nodeIds: string[] ) => {
    setExpanded( nodeIds );
  };

  const handleSelect = ( _event: React.SyntheticEvent, nodeId: string ) => {
    setSelected( nodeId );
  };

  const handleExpandClick = () => {
    setExpanded( ( oldExpanded ) =>
      oldExpanded.length === 0 ? expandNodes : []
    );
  };

  // To expand the whole tree on first render
  useEffect( ()=> {
    handleExpandClick();
  },[] )

  // To update the summaryData accordingly whenever a new model is selected in the tree
  useEffect( ()=> {
    const temp = getSelectedData( propData, selected );
    setSearchValue( '' )
    setDisplayMessage( false )
    setSummaryData( temp );
  }, [selected] );
  
  return <div className="contents">
    <div className={ classNames( {['leftBar']: !applicationSettings.pageSize.isExtraSmall, ['leftBar-mobile']: applicationSettings.pageSize.isExtraSmall}) }>
      <Autocomplete
        id="detailedSummarySearch"
        freeSolo
        options={ [] }
        value = { searchValue }
        className="detailedSummarySearchBar"
        data-testid="detailedSummarySearchBar"       
        renderInput={ ( params ) => <TextField { ...params } placeholder={ t( 'labels.search' ) } className="innerTextField"
          InputProps={ { ...params.InputProps,
            startAdornment:  <InputAdornment position="start"> <SearchIcon />
            </InputAdornment>
          } }
        /> }
        onChange={ ( _,newVal )=>{
          setSearchValue( newVal ? newVal : '' );
          handleDetailedSearch( newVal, setSummaryData, propData, selected, setDisplayMessage,showCode );
        } }
      />
      <Button onClick={ handleExpandClick }>
        {expanded.length === 0 ? t( 'labels.expandAll' ) : t( 'labels.collapseAll' ) }
      </Button>
      <SimpleTreeView
        aria-label="Left side product tree"
        slots={ {
          expandIcon: AddCircle,
          collapseIcon: RemoveCircle,
          endIcon: RemoveCircleEnd
        } }
        expandedItems={ expanded }
        onExpandedItemsChange={ handleToggle }
        onSelectedItemsChange={ handleSelect }
        className="detailedSummaryTree"
      >
        {renderTree( propData, configuration, expandNodes )}
      </SimpleTreeView>
    </div>
    <div className="rightBar">
      <div style={ {overflowY:'auto',height:'100%'} }>
        <Table stickyHeader aria-label="Detailed Configuration Summary Table" id="configurationTable">
          <TableHead className="tableHead1">
            <TableRow key="Header">
              <StyledTableCell style={ {width:'37%', wordBreak:'break-all'} }>{ t( 'labels.feature' ) }</StyledTableCell>
              <StyledTableCell style={ {width:'37%', wordBreak:'break-all'} }>{ t( 'labels.option' ) }</StyledTableCell>
              <StyledTableCell className="text-right" style={ {width:'6.5%'} }>{ t( 'labels.qty' ) }</StyledTableCell>
              {showOptional && <StyledTableCell style={ {width:'6.5%'} }>{ t( 'labels.optional' ) }</StyledTableCell>}
              {showPrice && <StyledTableCell className="text-right" style={ {width:'13%'} }>{ t( 'labels.lp' ) }</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody> 
            <CreateSection obj={ summaryData } index={ 0 } displayMessage={ displayMessage } value={ searchValue } showCode={ showCode } />
          </TableBody>
        </Table>
      </div>
      {showPrice &&
      <Table aria-label="Detailed Configuration Summary Table Footer">
        <TableHead>
          <TableRow key={ 'row' + propData.id + '_price' } >
            <StyledTableCell>{ t( 'labels.total' ) }</StyledTableCell>
            <StyledTableCell className="text-right">
              <span className={ !isValidCurrency( applicationSettings.currency?.currencyCode ) ? 'invalidCurrency' : 'content' }>
                <b> {applicationSettings.currency?.currencyCode && getSymbol( applicationSettings.currency?.countryCode, applicationSettings.currency?.currencyCode ) } </b>
              </span>
              {applicationSettings.currency.currencyCode && getFormattedPrice( calculatePrice( propData as ISectionPrice, configuration ) ) }
            </StyledTableCell>
          </TableRow>
        </TableHead>
      </Table>}

    </div>
  </div>
}