import { handleError } from '../services/ConfigurationDataHelperFunctions';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const NotifyAPI = {
  notify: ( action: { configurationId: string, token: string } ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/notify/save',
        { configurationId: action.configurationId },
        getHeaders( action.token ) 
      ).catch( ( err ) => {
        return handleError( err, 'notify' );
      } ),

  reset: ( configurationId: string, token: string ) =>
    AxiosClient
      .post( '/configurator/v1/configuration/reset',
        { configurationId: configurationId },getHeaders( token) )

};
