import { Autocomplete, TextField } from "@mui/material"
import React from "react"

const MultiValueShowControl = ({ options }: { options: string[] }) => {

    return (
        <Autocomplete
            multiple
            value={options}
            id="controllable-states-demo"
            options={options}
            sx={{ m: 1, width: 165 }}
            disabled={true}
            renderInput={(params) => <TextField {...params} />}
            className='clossapsed-view'
        />)
}

export default MultiValueShowControl;