import { Accordion, AccordionDetails, AccordionSummary, Box, Divider,Table, TableBody, TableCell, TableRow} from '@mui/material';
import React from 'react';
import { ProductScope } from '../data/Constants';
 import { ISectionSummaryCreateSection, ISectionSummaryRenderScope, ISectionSummaryRenderModel, ISummaryAccordionHeader, ISummarySectionProps,IBasePriceAccordion } from '../../types';
import { VariableSummary } from '.';
import { ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { getFormattedPrice, getAssignedValue, getSubmodelIdFromSectionId, getSubModelsFromSectionId, getVariableIdWithoutPrefixes, getViewIdSuffix, isScope, isSubModel, isSubModelAssigned, isSubmodelCountVariable,getNameWithCode,getSymbol, removeViewSuffix } from '../services';
import '../../public/svg/level0.svg';
import '../../public/svg/level1.svg';
import '../../public/svg/level2.svg';
import '../../public/svg/level3.svg';
import '../../public/svg/level4.svg';
import '../../public/svg/level5.svg';
import {t} from 'i18next';
import { isFeatureActive, isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
import { InCompleteIcon } from './InputComponents/InCompleteIcon';

/**
 * Returns true if section id represents a model
 * @param {string} id the id to be checked
 * @returns {boolean} whether a id represents a model or not
 */
function isModelSection( id:string ) {
  return id.endsWith( getViewIdSuffix() );
}

/**
 * Returns a component that renders all the non model sections and all the assigned variables in the given section
 * @param {ISectionSummaryCreateSection} param0 the props required by the component
 * @returns {JSX.Element} a component displaying non-model sections with selected variables' summary
 */
function createSectionComponent( {withTitle,section,depth,modelPath,isSimpleConfigurationSummary}:ISectionSummaryCreateSection ) {
  //Filter sections to not show Submodel sections or Scope section or no sections and variables are present in a particular section
  const sections = section.sections?.filter( ( s )=>!( isSubModel( s ) || isScope( s ) || s.sections.length === 0 && s.variables.length === 0 ) );
  const variables = section.variables?.filter( ( v )=>!( isSubmodelCountVariable( v ) || v.isMarkedOptional ) );
  let title = null;
  //Hiding the Section Name
  if( withTitle && !isSimpleConfigurationSummary) {
    title = <p className="contentHead" >
      {section.name}
    </p>
  }
  return <Box className="sectionSummary content">
    { title }
    <Box className="contentBody">
      {/*Passing isScopeVariable to the VariableSummary component*/}
      { variables?.map( ( v, key ) => <VariableSummary key={ `variable-${key}` } variable={ v } isScopeVariable={ false } isSimpleConfigurationSummary = {isSimpleConfigurationSummary}/> ) }
      { sections.map( ( s, key ) => <SectionSummary key={ `section-${key}` } section={ s } depth={ depth } modelPath={ modelPath }/> ) }
    </Box>
  </Box>
}

/**
 * Returns a component that renders the Optional items summary
 * @param {ISectionSummaryCreateSection} param0 the props required by the component
 * @returns {JSX.Element} a component displaying Optional items summary
 */
function createOptionalComponent( {withTitle,section,depth,modelPath,isSimpleConfigurationSummary}:ISectionSummaryCreateSection ) {
  const sections = section.sections?.filter( ( s )=>!( isSubModel( s ) || isScope( s ) ) );
  const variables = section.variables?.filter( ( v )=>!isSubmodelCountVariable( v ) );
  let title = null;
  //Hiding the Section Name
  if( withTitle && !isSimpleConfigurationSummary) {
    title = <p className="contentHead" >
      {section.name}
    </p>
  }
  return <Box className="sectionSummary content">
    { title }
    <Box className="contentBody">
      {/*Passing isScopeVariable to the VariableSummary component*/}
      { variables?.map( ( v, key ) => <VariableSummary key={ `variable-${key}` } variable={ v } isScopeVariable={ false } showOptional={ true } isSimpleConfigurationSummary = {isSimpleConfigurationSummary}/> ) }
      { sections.map( ( s ) => <React.Fragment key={ `section-${section.id}-${s.id}` }>{createOptionalComponent( {withTitle:true,section:s,depth,modelPath,isSimpleConfigurationSummary} )}</React.Fragment> ) }
    </Box>
  </Box>
}

/**
 * Returns the header component required for accordion depending on the model type
 * @param {ISummaryAccordionHeader} param0 the props required by the component
 * @returns {JSX.Element} a component representing the header for the model accordion based on model type
 */
function summaryAccordionHeader( {section,depth,getCountVariable}:ISummaryAccordionHeader ) {
  return <Box className="summaryAccordionHeader">
    
    <img src={ `../../public/svg/level${Math.min( depth,5 )}.svg` }/>&nbsp;
    <span className="accordianTitle">
      <h6 className="accordionHead">
        {
          isSubModel( section )
            ? `${section.name} (x${getAssignedValue( getCountVariable && getCountVariable( section ) || null )?.value})`
            : `${section.name}`
        }
      </h6>&nbsp;
      {!section.isComplete ? <InCompleteIcon/> : null}
    </span>
  </Box>
}
// Shows Base Price Content of Models on Summary Panel if available
function basePriceHeader( {section,getCountVariable,userSettings}:IBasePriceAccordion , currency: string, countryCode: string ) {
  return <Table size="small" aria-label="Base Model Summary Table">
    <TableBody> 
      <TableRow>
        <TableCell component="th" className={ 'baseModelName ' } >
          {<span className="baseHead"><b>
            {
              isSubModel( section )
                ? `${getNameWithCode( {id: section.productId, name: section.name},userSettings.showCode,'true' )} (x${getAssignedValue( getCountVariable && getCountVariable( section ) || null )?.value})`
                : getNameWithCode( {id: section.productId, name: section.name},userSettings.showCode,'true' )
            }        
          </b> </span>}
        </TableCell>
        <TableCell component="th" className="baseModelPriceCell" >
          <span className="content" color="text.secondary" >
            {currency && getSymbol( countryCode, currency ) } 
            { section.basePrice && section.basePrice >= 0 && getFormattedPrice( section.basePrice )}
          </span>
        </TableCell>
        
      </TableRow> 
    </TableBody>
  </Table>
}

const RenderAccordionSummary = ( {setAccordionValue,section,depth,getCountVariable,currency,countryCode, claimsData} : ISectionSummaryRenderScope )=>{
  return<AccordionSummary onClick={ ()=> setAccordionValue && setAccordionValue( section.id ) } className="acSummary" expandIcon={ <ExpandMore /> }>
    { 
      summaryAccordionHeader( {section,depth,getCountVariable} )
    }
    { isFeatureVisible( claimsData.featureFlags.ListPrice ) &&
    <Box className="modelPrice">
      <span>
        <span className={ !isValidCurrency( currency ) ? 'invalidCurrency' : '' }>
          {currency && getSymbol( countryCode, currency ) } 
        </span>
        {currency && getFormattedPrice( section.basePrice ? section.basePrice + section.price : section.price )}
      </span>
    </Box>
    }
 
  </AccordionSummary>
}

const sectionBasePrice = ( section,getCountVariable,userSettings,currency, countryCode )=>{
  if( section.basePrice >= 0 ) {
    return basePriceHeader( {section,getCountVariable,userSettings},currency, countryCode );
  }else{
    return null;
  }
}

const getDivider = (isSimpleConfigurationSummary: boolean) => {
  return !isSimpleConfigurationSummary ? <Divider className="divider"/> : null;
}

/**
 * Returns an Accordion that represents the model's sumary
 * @param {ISectionSummaryRenderScope} param0 the props required by the component
 * @returns {JSX.Element} the Accordion that represents the model's sumary
 */
function renderModel( {section,modelPath,depth,accordionValue,setAccordionValue,currency,getCountVariable,userSettings,countryCode, claimsData, isSimpleConfigurationSummary}:ISectionSummaryRenderScope ) {
  const currentModel = getSubmodelIdFromSectionId( section.id );
  return modelPath.length === 1 || depth >= modelPath.length || depth === modelPath.length - 1 && modelPath[depth] === currentModel 
    ? <Accordion className="sectionSummary root" expanded={ accordionValue === section.id } >
      <RenderAccordionSummary setAccordionValue={ setAccordionValue }section={ section } depth={ depth } getCountVariable={ getCountVariable } currency={ currency } countryCode={ countryCode } claimsData={ claimsData }/>
      {isFeatureVisible( claimsData.featureFlags.ListPrice ) && sectionBasePrice( section,getCountVariable,userSettings,currency, countryCode )}
      <AccordionDetails>
        { createSectionComponent( {withTitle:false,section,depth,modelPath,isSimpleConfigurationSummary} ) }
        {
          isFeatureVisible( claimsData.featureFlags.Optional ) && section.optional && <>
            {getDivider(isSimpleConfigurationSummary)}
            <p className="contentHead">
              <u>{`${t( 'labels.optionalItems' )}`}</u>
            </p>
            {createOptionalComponent( {withTitle:false,section:section.optional,depth,modelPath,isSimpleConfigurationSummary} )}</> 
          || null
        }
      </AccordionDetails>
    </Accordion>
    : null
}

/**
 * Returns an Accordion displaying the scope for the configuration
 * @param {ISectionSummaryRenderModel} param0 the props required by the component
 * @returns {JSX.Element} an Accordion displaying the scope for the configuration
 */
function renderScope( {section,accordionValue,setAccordionValue,isSimpleConfigurationSummary}:ISectionSummaryRenderModel ) {
  return <Accordion className="sectionSummary root" expanded={ accordionValue === section.id } onClick={ ()=>setAccordionValue && setAccordionValue( section.id ) }>
    <AccordionSummary className="acSummary" expandIcon={ <ExpandMore /> }>
      <h6 className="accordionHead">{ t( `labels.${section.name}` ) }</h6>
    </AccordionSummary>
    <AccordionDetails>
      <Box className="sectionSummary content">
        <Box className="contentBody">
          { section.variables?.map( ( v, key ) => <VariableSummary key={ `variable-${key}` } variable={ v } isScopeVariable={ true } isSimpleConfigurationSummary = {isSimpleConfigurationSummary}/> ) }
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>
}

/**
 * Handles subsections and variables in configuration summary.
 * Renders first variables of the section, then subsections.
 * @param {ISummarySectionProps} props the properties for the section summary component
 * @returns {JSX.Element} the section summary component
 */
export const SectionSummary = ( { section, depth, accordionValue, getCountVariable, setAccordionValue, displayScope,modelPath } : ISummarySectionProps ) => {
  
  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const configuration = useSelector( ( state: AppState )=> state.configuration )
  const claimsData = useSelector( ( state: AppState )=> state.claimsData )
  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )
  //If this feature flag is active, both Section Name and Feature Family information will be hidden
  const isSimpleConfigurationSummary = isFeatureActive(claimsData.featureFlags.SimpleConfigurationSummary);

  if( !section || !modelPath || !configuration || !applicationSettings ) {
    return null;
  }
  const submodels = getSubModelsFromSectionId( section.id );
  const isSubmodel = isSubModel( section );
  const currentModel = getSubmodelIdFromSectionId( section.id );
  const models = section.sections?.filter( ( s ) => isSubModel( s ) );
  if( isSubmodel && !isSubModelAssigned( configuration, submodels ) ) {
    return null;
  }
  //Add property product id to submodels
  if( isSubmodel ) {
    section.productId = removeViewSuffix( section.id )
  }
  if( displayScope ) {
    section.variables = section.variables.filter( ( variable )=>ProductScope.EventId !== getVariableIdWithoutPrefixes( variable ) );
    return renderScope( {section,accordionValue,setAccordionValue,isSimpleConfigurationSummary} );
  } else if( isModelSection( section.id ) ) {
    return <>
      {
        renderModel( {section,modelPath,depth,accordionValue,setAccordionValue,currency:applicationSettings.currency.currencyCode,getCountVariable,userSettings,countryCode:applicationSettings.currency.countryCode, claimsData, isSimpleConfigurationSummary} )
      }
      {
        modelPath.length === 1 || depth < modelPath.length && modelPath[depth] === currentModel ? models?.map( ( s, key ) => <SectionSummary key={ `section-${key}` } section={ s } depth={ depth + 1 } accordionValue={ accordionValue } setAccordionValue={ setAccordionValue } modelPath={ modelPath } getCountVariable={ getCountVariable }/> ) : null
      }
    </>
  }
  return createSectionComponent( {withTitle:true,section,depth,modelPath,isSimpleConfigurationSummary} );
}