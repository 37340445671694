import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DefaultUserSettings } from '../../data/Constants';
import { UserSettingsApi } from '../../api/UserSettingsApi';
import { IUserPreferences } from '../../../types';

const initialState: IUserPreferences = {
  ...DefaultUserSettings,
  preferenceChanged: false,
  isUserSettingsDefault: false
};

//Gets User preferences 
export const getUserPreferences = createAsyncThunk( 'userPreferences/getUserPreferences', async ( action: { token: string } ) => {
  return UserSettingsApi.getUsersettings( action );
} );

//Updates user preferences
export const updateUserPreferences = createAsyncThunk( 'userPreferences/updateUserPreferences', async ( action: { token: string, request: any } ) => {
  return UserSettingsApi.updateUsersettings( action );
} );


const userPreferencesSlice = createSlice( {
  name: 'userPreferences',
  initialState,
  reducers: {
    setUserPreferences( state, action ) {
      return { ...state, ...action.payload }
    }
  },
  extraReducers: ( builder ) => {
    builder.addCase( getUserPreferences.fulfilled, ( state, action ) => {
      return updateState( state, action );
    } );
    builder.addCase( updateUserPreferences.fulfilled, ( state, action ) => {
      return updateState( state, action );
    } );
  }
} )

function updateState( state, action ) {
  if ( action.payload?.data ) {
    return { ...state, ...action.payload.data }
  }
  return state;
}

export const { setUserPreferences } = userPreferencesSlice.actions;


export default userPreferencesSlice.reducer

