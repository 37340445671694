import {
  IconButton,
  MenuItem,
  Tooltip,
  Paper,
  Popper,
  Box,
  MenuList,
  ClickAwayListener
} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store';
import { SessionStore } from '../services/SessionStore';
import { getLanguageCode, getConfigureRequest, UrlHelper } from '../services';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableViewIcon from '@mui/icons-material/TableView';
import { EUrlParams, ESessionStore,PopperModifier } from '../data/Constants';
import { AppAuthContext } from '../services/Contexts';
import { resetAllProductActiveTabs } from '../store/states/ApplicationSettingsSlice';
import { resetError } from '../store/states/ErrorSlice';
import { configure, validViewId } from '../store/states/ConfigurationSlice';


const isViewDisabled = ( viewId:string,viewList:string[] ) => {
  return viewList.length === 1 || viewId === SessionStore.get( ESessionStore.ViewId )
}

/**
 * Renders the ViewHandler dropdown where the user can change the view
 * @returns {JSX.Element} the viewHandler Element
 */
export const ViewHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const token = useContext( AppAuthContext );
  const {t} = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const [arrowRef, setArrowRef] = React.useState( null );
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId );

  //selectors
  const configuration = useSelector( ( state: AppState )=> state.configuration )


  /*
   * Method to handle the change of viewId in ViewHandler DropDown and call configure method
   * @param {any} event the value of viewId
   * @returns {JSX.Element} the viewHandler Element
   */

  const onHandleView = ( viewID: string ) => {
    SessionStore.set( ESessionStore.ViewId, viewID );
    const request = getConfigureRequest( configuration );
    if ( !request ) {
      return;
    }
    dispatch( configure( { request: request,token: token,language: getLanguageCode() } ) );
    // To Reset ErrorDialog after selecting ViewId
    dispatch( resetError() );
    dispatch( validViewId() )
    handleClose()
    dispatch( resetAllProductActiveTabs() )// remove existing product model wise tab data from the state 
    // To remove invalid viewId from query params of URL
    const params = UrlHelper.deleteSearchParameter( ESessionStore.ViewId );
    navigate( {search: params}, {replace:true} );
  };
  const handleClickOpen = ( event: React.MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };
  const handleClose = () => {
    setAnchorEl( null );
  };
  useEffect( () => {
    if( configuration.viewIds && configuration.viewIds.length > 0 ) {
      const urlViewId = UrlHelper.getSearchParameter( ESessionStore.ViewId );
      if( configuration.viewIds.length === 1 && urlViewId ) {
        const params = UrlHelper.deleteSearchParameter( ESessionStore.ViewId );
        navigate( {search: params}, {replace:true} );
        SessionStore.set( ESessionStore.ViewId ,configuration.viewIds[0] );
      }
    }
  } ,[] );
  const viewList:string[] = configuration.viewIds && configuration.viewIds.length > 0 ? configuration.viewIds : [];
  return (
    <>
      <Tooltip title={ t( 'tooltip.switchView' ) } >
        <IconButton  
          aria-label={ 'Switch View' }
          aria-controls="long-menu"
          aria-haspopup="true"
          disabled={ !configurationId }
          onClick={ handleClickOpen }
          className= { `border-radius-0 icon-font-size ${anchorEl ? 'activeIcon' : ''}` } 
          data-testid="btn-switch-view"
        >
          <TableViewIcon/>
        </IconButton>
      </Tooltip>
      <Popper open={ open } anchorEl={ anchorEl } placement="bottom" role="tooltip" className="zindex-100"
        modifiers={ [
          ...PopperModifier,
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            }
          }
        ] }
      >
        <div role="tooltip">
          <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef } />
          <Box >
            <Paper >
              <ClickAwayListener onClickAway={ handleClose }>
                <MenuList 
                  autoFocusItem={ open }
                  id="ViewHandler-menu"
                  aria-labelledby="SwitchView-button"
                  data-testid="menu-view"
                >
                  {viewList && viewList.length > 0 ? viewList.map( ( value ) => 
                    <MenuItem
                      selected={ value === SessionStore.get( ESessionStore.ViewId ) }
                      onClick={ () => onHandleView( value ) }
                      value={ value }
                      key={ value }
                      className="viewHandlerList"
                      disabled = { isViewDisabled( value,viewList ) }
                      data-testid ="viewHandlerList"
                    >
                      {value.split( '_' )[1]}
                    </MenuItem>
                  ) : <MenuItem onClick={ () =>handleClose }>
                    {t( 'errorMessages.viewId.noView' )} 
                  </MenuItem>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Box>
        </div>
      </Popper>
    </>
  );
};
