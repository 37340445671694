import { Box, FormControlLabel, Grid, Radio, RadioGroup, SelectChangeEvent, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getNameWithCode } from '../../services';
import { ISingleValueSelectControlProps,IConfigurationValue } from '../../../types';
import { IFeatureOrFamily } from '../../../types/IHelperTypes';
import { InfoDialogIcon } from './InfoDialogIcon';
import { gridLayout } from '../SectionLayout';
import { useTranslation } from 'react-i18next';
import { OptionalControl,Price} from '..';
import {checkIsOptionReadonly,checkIsOptionHidden} from '../../services/ConfigurationDataHelperFunctions';
import { AppState } from '../../store';

/**
 * Renders a dropdown which allows to select only one value.
 * @param {ISingleValueSelectControlProps} props the properties for the dropdown component
 * @returns {JSX.Element} the dropdown component
 */
export const SingleValueSelectControl = ( { featureFlags, values, value, onChange, onOptionalChange,variable,disabled }: ISingleValueSelectControlProps ) => {
  const {t} = useTranslation();

  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  
  const onInternalChange = ( e: SelectChangeEvent ) => onChange( e.target.value );
  const assignedValue = values?.find( v=> v.state.isAssigned );
  const readOnly = assignedValue && assignedValue.assigned === 'Rule' ? checkIsOptionReadonly( assignedValue ) : false;
  const setReadonly = ( val:IConfigurationValue )=>{
    return readOnly ? readOnly : checkIsOptionReadonly( val );
  }

  return <RadioGroup
    aria-labelledby="dynamic-input-control"
    name="single-select-control-group"
    value={ value }
    onChange={ onInternalChange }
    className="w-100"
  >
    <Grid container item xs={ gridLayout.fullWidth }>
      {values?.map( ( v )=> {
        const isFeatureReadOnly = setReadonly( v );
        const hidden = checkIsOptionHidden( v );
        /*
          If hidden return null. Return null will not show any input control for particular variable in UI.
          It doesn't have any impact on configuration summary, price or any other functionality of application
        */  
        if ( hidden || v.suppressed ) {
          return null;
        }
        return <Grid key={ v.value } container className={ v.state.isIncompatible ? 'incompatible' : '' } item xs={ gridLayout.fullWidth } alignItems="center">
          <Grid container item xs={ gridLayout.xs.input } sm={ gridLayout.sm.input } justifyContent="left" alignItems="center" className="label-overflow">
            <Tooltip title={ getNameWithCode( v as IFeatureOrFamily,userSettings.showCode ) }>
              <FormControlLabel className={ v.isSearchValueMatched ? 'SearchValueMatched' : '' } label={ getNameWithCode( v as IFeatureOrFamily,userSettings.showCode ) } value={ v.value } control={ <Radio className="paddingTopBottom"/> } disabled={ disabled ? true : isFeatureReadOnly }/>
            </Tooltip>
          </Grid>
          <Grid container item xs={ gridLayout.xs.infoIcon } sm={ gridLayout.sm.infoIcon } alignItems="center" justifyContent="center">
            <InfoDialogIcon variable={ v as IFeatureOrFamily }/>
          </Grid>
          <Grid container item xs={ gridLayout.xs.optional } sm={ gridLayout.sm.optional } alignItems="center" justifyContent="center">
            <OptionalControl variable={ v } onOptionalChange={ onOptionalChange } featureFamily={ variable } disabled={ disabled ? true : isFeatureReadOnly } featureFlag = { featureFlags?.Optional }/>
          </Grid>
          <Grid container item xs={ gridLayout.xs.price } sm={ gridLayout.sm.price } alignItems="right" justifyContent="right">
            <Box display={ {xs:'inline',sm:'none'} } textAlign="center">{t( 'labels.listPrice' )}:&nbsp;</Box>
            <Price price={ v.price } isBundled={ v.isBundled }/>
          </Grid>
        </Grid>
      }
      )}
    </Grid>
  </RadioGroup>
}