import { Box,Dialog,DialogContent,DialogTitle, IconButton, Tab} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState} from '../store';
import { IFeatureOrFamily,IUserSettings } from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { getNameWithCode } from '../services';
import CloseIcon from '@mui/icons-material/Close';
import { SalesText } from './SalesText';
import { OrderingInstructions } from './OrderingInstructions';
import { ImageGallery } from './ImageGallery';
import { ETabValue } from '../data/Constants';
import DraggableComponent from './DraggableComponent';
import { setInfoDialogVariable } from '../store/states/ConfigurationSlice';

const getTitle = ( variable:IFeatureOrFamily,userSettings:IUserSettings )=>{
  return variable.variableType === 'PM' ? variable.name : getNameWithCode( variable,userSettings.showCode );
}
/**
   * Renders the short sales text popup.
   * @returns {JSX.Element} the short sales text popup component
   */
export const InfoDialog = () => {
  const [TabValue, setTabValue] = React.useState<string|null>( 'SalesText' );
  const {t} = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const variable = useSelector( ( state: AppState )=> state.configuration?.infoDialogVariable )
  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };
  const onClose = () => {
    dispatch( setInfoDialogVariable( {infoDialogVariable: null} ) ) 
    setTabValue( 'SalesText' );
  };
  const title = variable ? getTitle( variable,userSettings ) : '';


  return variable && <Dialog className="common-dialog-style infoDialogStyle cursor-move" open={ true } onClose={ onClose } PaperProps={ {className:'paper'} } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title" >
    <DialogTitle className="header title">
      <div className="infoDialogTitle">{title}</div> 
    </DialogTitle>
    <DialogContent >
      <IconButton className="closeIcon"
        aria-label="close"
        onClick={ onClose }
      >
        <CloseIcon />
      </IconButton>
      <TabContext value={ TabValue || ETabValue.SalesText }>
        <Box className="dialog-box">
          <TabList onChange={ handleChange } >
            <Tab className="dialog-tab"
              value={ ETabValue.SalesText } label={ t( 'labels.salesText' ) }
            />
            <Tab className="dialog-tab" 
              value={ ETabValue.OrderingInstructions } label={ t( 'labels.orderingInstructions' ) }
            />
            <Tab className="dialog-tab" 
              value={ ETabValue.Images } label={ t( 'labels.images' ) }
            />
          </TabList>
        </Box>
        <TabPanel className="dialog-panel  sales-text-dialog" value={ ETabValue.SalesText } >
          <SalesText variable={ variable }/>
        </TabPanel>
        <TabPanel className="dialog-panel ordering-instructions-dialog " value={ ETabValue.OrderingInstructions }>
          <OrderingInstructions variable={ variable }/>
        </TabPanel>
        <TabPanel className="dialog-panel ordering-instructions-dialog productImageDialog" value={ ETabValue.Images }>
          <ImageGallery variable={ variable }/>
        </TabPanel>
      </TabContext>
    </DialogContent>
  </Dialog> || null
}