import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConflictListItemText, getLanguageCode, getLastAssignmentVariable, getSubmodelIdFromSectionId, onRevertAssignment, getConfigureRequest, createId, getVariableFromAllSections, getFeatureId } from '../services';
import { AppDispatch, AppState } from '../store';
import { IConfigurationValue, IConfigurationVariable, IConflictDataPresentationType, IConflictDataSectionType, IConflictDataStoreType, IConflictDialogConflictType, IConflictHierarchy, IModelContext, IModelContextHierarchy, INamedWOCode, ISection, ISubModelInfo, IValueType, IVariableAssignment } from '../../types';
import { ConflictTable } from './ConflictTable';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppAuthContext } from '../services/Contexts';
import DraggableComponent from './DraggableComponent';
import { clearConflict, configure, setOptionalData, setOptionalItems } from '../store/states/ConfigurationSlice';

/**
 * To initialize dataStore required for conflict table
 * @param {string} desc the model path description
 * @param {IConflictStoreType} dataStore the structure to store data
 * @param {INamed[]} pModelPath the array representing the model paths
 * @param {IModelContextHierarchy} node the hierarchy of models
 * @param {IModelContext} modelContext the model context
 * @returns {void}
 */
function initDataStore( desc:string, dataStore:IConflictDataStoreType, pModelPath:INamedWOCode[],node?:IModelContextHierarchy ,modelContext?:IModelContext ) {
  if( !node || !modelContext ) {
    return;
  }
  for( const child of node?.children || [] ) {
    const $name = modelContext.subModels.find( ( subModel ) => subModel.id === child.id )?.name || '';
    const $desc = `${desc} > ${$name}`;
    const $modelPath = [...pModelPath , {id:child.id,name:$name}]
    dataStore[child.id] = {
      id:child.id,
      name: $name,
      desc: $desc,
      isModelConflict:true,
      modelPath:$modelPath,
      sections:{},
      conflicts:{}
    }
    initDataStore( $desc, dataStore , $modelPath , child , modelContext );
  }
}

/**
 * To segregate conflicts into the models
 * @param {IConflictHierarchy} modelConflicts the conflicts that have occured
 * @param {string} modelId the modelId
 * @param {IConflictDataStoreType} dataStore the structure to segregate conflicts in
 * @returns {void}
 */
function addConflictsToModels( modelConflicts:IConflictHierarchy,modelId:string,dataStore:IConflictDataStoreType ) {
  if( !modelConflicts || !Object.keys( modelConflicts ).length ) {
    return
  }
  if( modelConflicts.conflicts ) {
    const $conflicts:{[key:string]:IConflictDialogConflictType} = {};
    modelConflicts.conflicts.forEach( ( conflict )=>{
      if( !( conflict.variable.id in $conflicts ) ) {
        $conflicts[conflict.variable.id] = {
          id:conflict.variable.id, 
          name:conflict.variable.name, 
          values : [],
          shortSalesText : conflict.variable.shortSalesText
        }
      }
      $conflicts[conflict.variable.id].values.push( conflict.value );
      dataStore[modelId].conflicts = $conflicts;
    } );
  }
  Object.keys( modelConflicts ).forEach( ( key:string ) => {
    if( key !== 'conflicts' ) {
      addConflictsToModels( modelConflicts[key] as IConflictHierarchy,key,dataStore ) ;
    }
  } );
}

/**
 * To check and add conflicts to the section if there is a conflict
 * @param {IConflictDataStoreType} dataStore the structure to segregate conflicts on
 * @param {string} modelId the modelId
 * @param {IConfigurationVariable[]} variables the variables in the section
 * @param {IConflictDataSectionType} section the section to store data
 * @returns {boolean} whether to add section has conflicts or not
 */
function getSectionConflicts( dataStore:IConflictDataStoreType,modelId:string,variables: IConfigurationVariable[],
  section:IConflictDataSectionType ):boolean {
  let flg = false;
  if( dataStore[modelId]?.conflicts ) {
    for( const variable of variables ) {
      if( variable.id in dataStore[modelId].conflicts ) {
        flg = true;
        section.conflicts.push( dataStore[modelId].conflicts[variable.id] ); 
      }else{
        dataStore[modelId].isModelConflict = false;
      }
    }
  }
  return flg;
}

/**
 * To segregate conflicts into their respective sections
 * @param {IConflictDataStoreType} dataStore the structure to segregate conflicts on
 * @param {string} modelId the modelId
 * @param {ISection} section the section
 * @returns {void}
 */
function addConflictsToSections( dataStore:IConflictDataStoreType,modelId:string,section:ISection ) {
  if( !dataStore || !modelId || !section ) {
    return ;
  }
  const $modelId = getSubmodelIdFromSectionId( section?.id || '' );
  const variables = section?.variables || [];
  const sections = section?.sections || [];
  if( $modelId ) {
    modelId = $modelId;
  }
  const $section:IConflictDataSectionType = {
    id:section.id,
    name:section.name || '',
    conflicts:[]
  }
  const flg = getSectionConflicts( dataStore,modelId,variables,$section );
  if( flg ) {
    dataStore[modelId].sections[section.id] = $section;
  }
  
  for( const sec of sections ) {
    addConflictsToSections( dataStore,modelId,sec );
  }
}

/**
 * To get the final structure required for representing the conflict table
 * @param {ISubModelInfo} rootModel the root model data
 * @param {IModelContext} modelContext the model context
 * @param {IConflictHierarchy} conflicts the conflicts that occcured
 * @param {ISection[]} rootSections the sections as received in response
 * @returns {IConflictDataPresentationType[]} the structure required for representing conflicts
 */
function getTableRows( rootModel:ISubModelInfo,modelContext:IModelContext,conflicts:IConflictHierarchy,rootSections:ISection[] ):IConflictDataPresentationType[] {
  const dataStore:IConflictDataStoreType = {
    [rootModel.id]:{
      id:rootModel.id,
      name:rootModel.name,
      desc:rootModel.name,
      isModelConflict:true,
      modelPath: [{id:rootModel.id,name:rootModel.name}],
      sections:{},
      conflicts:{}
    }
  };
  initDataStore( rootModel.name,dataStore,[{id:rootModel.id,name:rootModel.name}],modelContext?.hierarchy,modelContext );
  addConflictsToModels( conflicts,rootModel.id,dataStore );
  for( const sec of rootSections ) {
    addConflictsToSections( dataStore,rootModel.id,sec );
  }
  return Object.values( dataStore ).filter( model => Object.keys( model?.conflicts || {} ).length )?.map( model => ( {...model,sections:Object.values( model.sections )} ) )
}

function IsLastAssignmentInConflict( lastAssignmentVariable:IConfigurationVariable|null,lastAssignmentValue:IConfigurationValue|IValueType,removedAssignments?:IVariableAssignment[] ) {
  if( !removedAssignments?.length || !lastAssignmentValue || !lastAssignmentVariable ) {
    return false;
  }
  let lastValue:{value?:IValueType};
  if( typeof lastAssignmentValue !== 'object' ) {
    lastValue = {value:lastAssignmentValue}
  }else{
    lastValue = lastAssignmentValue;
  }  
  return removedAssignments?.some( ( v:IVariableAssignment ) => {
    return lastAssignmentVariable.id === v.variable.id && lastValue?.value === v.value.value!.toString();
  } )
}

//Revert the last assignment When User selects Cancel 
const onRevert = ( configuration, token: string, dispatch: AppDispatch ) => {
  const request = onRevertAssignment( configuration );
  const variable = getVariableFromAllSections( configuration, configuration.lastChange?.variableId );
  if( variable ) {
    const featureId = getFeatureId( configuration.lastChange?.value, variable );
    const id = createId( featureId, variable );
    configuration?.savedConfiguration?.optionalItems?.delete( id );
    const optionalItems = configuration.savedConfiguration?.optionalItems ? [...configuration.savedConfiguration.optionalItems.keys()] : []
    dispatch( setOptionalItems( {token: token,configurationId: configuration.configurationId,optionalItems: optionalItems } ) ).then( ( res )=>{
      dispatch( setOptionalData( {optionalItems: configuration.savedConfiguration?.optionalItems} ) )
    } );
  } 
  if( request ) {
    request.optionalItems = [ ...configuration.savedConfiguration.optionalItems.keys()]
    dispatch( configure( {request: request, token: token, language: getLanguageCode() } ) );
  }
};

//Keeps the selection and removes the conflicted assignment
const keepSelection = ( configuration, token: string, dispatch: AppDispatch )=>{
  configuration.conflict?.assignmentsToRemove.conflicts.forEach( v=>{
    const variable = getVariableFromAllSections( configuration, v.variable.id );
    if( variable ) {
      const featureId = getFeatureId( v.value.value, variable );
      const id = createId( featureId, variable );
      configuration?.savedConfiguration?.optionalItems?.delete( id )
    }
  } );
  const optionalItems = configuration.savedConfiguration?.optionalItems ? [...configuration.savedConfiguration.optionalItems.keys()] : []
  dispatch( setOptionalItems( {token: token,configurationId: configuration.configurationId, optionalItems: optionalItems} ) ).then( ( res )=>{
    dispatch( setOptionalData( {optionalItems: configuration.savedConfiguration?.optionalItems} ) )
  } );
  const request = getConfigureRequest( configuration, true );
  dispatch( configure( { request: request,token: token, language: getLanguageCode() } ) );
  dispatch( clearConflict() ); 
}

/**
 * Renders the conflict dialog when the user selects an assignments which is causing a conflict
 * @returns {JSX.Element} the conflict dialog
 */
export const ConflictDialog = ( ) => {
  const {t} = useTranslation();
  const token:string = useContext( AppAuthContext );

  const userSettings = useSelector( ( state: AppState )=> state.userSettings )
  const configuration = structuredClone( useSelector( ( state: AppState )=> state.configuration ) )
  const applicationSettings = useSelector( ( state: AppState )=> state.applicationSettings )

  const dispatch = useDispatch<AppDispatch>();
  const [conflictsState,setConflictsState] = React.useState<IConflictDataPresentationType[]>( [] );
  const conflicts = configuration.conflict;
  const rootSections = configuration.data?.sections || [];
  const modelContext = configuration.savedConfiguration?.modelContext;
  const rootModel = configuration.savedConfiguration?.modelContext.rootModel;
  let isLastAssignmentInConflict:boolean|null = null ;

  useEffect( ()=>{
    if( !isLastAssignmentInConflict && conflicts && rootModel ) {
      const $assignmentsToRemove = conflicts?.assignmentsToRemove || {} as IConflictHierarchy;
      setConflictsState( getTableRows( rootModel,modelContext,$assignmentsToRemove,rootSections ) );
    }
  },[conflicts?.assignmentsToRemove, isLastAssignmentInConflict] );

  if ( !configuration.conflict || !configuration.lastChange || !configuration.data.removedAssignments ) {
    return null;
  }

  const showCode = !!userSettings?.showCode;
  const lastAssignmentVariable = getLastAssignmentVariable( configuration );
  let lastAssignmentValue : IConfigurationValue | IValueType = lastAssignmentVariable?.values?.find( v => v.value === configuration.lastChange?.value );

  if (
    !lastAssignmentValue &&
    configuration.lastChange.value === true &&
    lastAssignmentVariable?.values?.length === 1
  ) {
    lastAssignmentValue = lastAssignmentVariable?.values[0];
  }

  if ( !lastAssignmentValue ) {
    lastAssignmentValue = configuration.lastChange.value;
  }

  //Check if last assigment is also your removed assignment
  isLastAssignmentInConflict = IsLastAssignmentInConflict( lastAssignmentVariable,lastAssignmentValue,configuration.data.removedAssignments?.variableAssignments );
  
  const classSelector = Number( applicationSettings?.pageSize?.isExtraSmall );
  const flexDirection = ['flex-row','flex-column'];
  const alignSelf = ['alignself-center','alignself-start'];
  const maxWidth = ['mw-25','mw-100'];
  const toolTipModifier = [{
      name: 'offset',
      options: {
        offset: [-10, 0], // Adjust the first value to move left/right
      },
    },
  ];
  return (
    isLastAssignmentInConflict ? 
      <Dialog className="conflictDialog-root"
        open
        aria-describedby="alert-dialog-description"
        aria-labelledby = "draggable-dialog-title"
        PaperComponent={ DraggableComponent }
      >
        <DialogTitle className="header d-flex align-center cursor-move h-auto" >
          {t( 'conflictDialog.title' )}
        </DialogTitle>
        <DialogContent>
          <Box className="conflictError" >
            <CancelIcon className="btn-decrement cancelIcon"/> 
            <span >
              {t( 'conflictDialog.otherConflictError' )}</span>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className="text-capitalize" color="info" variant="contained" onClick={ ()=> onRevert( configuration, token, dispatch ) }>{t( 'button.ok' )}</Button>
        </DialogActions>
      </Dialog>
      :
      <Dialog className="conflictDialog-root" open PaperProps={ {
        className:'conflictDialog-paper'
      } } PaperComponent={ DraggableComponent } aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle className="header d-flex align-center cursor-move h-auto" >
          {t( 'conflictDialog.title' )}
        </DialogTitle>
        <DialogContent>
          <Box className={ `selection ${flexDirection[classSelector]}` } >
            <Box className={ `selectionLabel ${alignSelf[classSelector]} ${maxWidth[classSelector]}` }>
              {`${t( 'labels.selection' )}`}
            </Box> 
            <Box className="selectionValue">
              {getConflictListItemText( lastAssignmentVariable, lastAssignmentValue, showCode ,true )}
            </Box>
          </Box>
          <ConflictTable modelRows={ conflictsState } showCode = { showCode } />
        </DialogContent>
        <DialogActions className="footer">
        <Tooltip title={ t( 'tooltip.keepSelection' ) } placement='top' arrow PopperProps={{
        modifiers: toolTipModifier,
        style: {maxWidth: '120px'}}}>
          <Button className="text-capitalize" color="info" variant="contained" onClick={ ()=>keepSelection( configuration, token, dispatch ) }>
            {t( 'button.keepSelection' )}
          </Button>
          </Tooltip>
          <Button className="text-capitalize" color="primary" variant="contained" onClick={ ()=> onRevert( configuration, token, dispatch ) }>
            {t( 'button.cancel' )}
          </Button>
        </DialogActions>
      </Dialog>
  );
};