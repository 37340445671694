import { UrlHelper } from '.';
import { IFeatureOrFamily, IExtendedConfigureResponse, IConfiguration, IConfigurationVariable, ILastChange, ISection, IValueType, IVariableAssignment, IConfigureRequest, IConflictHierarchy, INamed, IVariableValueAssignment, IConfigurationValue, IUserAssignment, IAssignment, IConflictDialogConflictType, ISelectableSectionsReturnType, IConfigurationProperty, IContextData, ICountry, IPropertyAssociation, IClaimsData, IFeatureFlags, INamedWOCode, IApplicationSettings, IAssociationProperty, IpropertyDefinition, IMadatoryPDMAndConfItProperties, IQueryParamProps, ISearchOption, IOrderingInstructions } from '../../types';
import { ESectionId, ESessionStore, EChangeType, EVariableType, ESectionInfoType, ModelInclusion, PriorityValue, IdFormat, EJustification, EConfigItProperty, DefaultLanguage, EUrlParams, ProductSection,EPageNames ,ApplicationSupportedLanguages, ETabValue, ProductScope, PdmProperties, EConfigurationType, EPDMProperty, ConfigitProperties, ApiUrl, QueryParams, EPropertyType } from '../data/Constants';
import { SessionStore } from './SessionStore';
import { AppStore } from '../store';
import { t } from 'i18next';
import { isScope, isSection, isSubmodelCountVariable } from './SummaryHelperFunction';
import { countryCodes as codes } from '../localization';
import { appSettings } from '../settings';
import { useAuth } from 'oidc-react';
import { IsDefaultFlowInSessionStore } from './SessionStoreHelperFunctions';
import { resetApplicationSettings } from '../store/states/ApplicationSettingsSlice';
import { resetMyConfigurations } from '../store/states/ProductSettingsSlice';
import { changeLandingTab, resetConfiguration, setOptionalData } from '../store/states/ConfigurationSlice';
import { setError } from '../store/states/ErrorSlice';
import { AxiosResponse } from 'axios';

const countryCodes: ICountry = codes.codes;

export const GetToken = () => {
  let token = '';
  let auth = '';
  if ( IsAppOpenedInIframe() ) {
    token = UrlHelper.getSearchParameter( EUrlParams.Token ) || SessionStore.get( ESessionStore.Token );
  } else if ( IsAppOpenedByExtApp() ) {
    token = SessionStore.get( ESessionStore.Token );
  } else {
    auth = useAuth();
    token = auth.userData?.access_token;
  }
  return { token, auth };
}


//Get the API Url with query params
export function getApiUrl( url:string, queryParamProps: IQueryParamProps ) {
  const { packagePath, productModel, apiType } = queryParamProps
  switch( url ) {
    case ApiUrl.ImagesApi:
      return `${ApiUrl.ImagesApi}?${QueryParams.PackagePath}=${ packagePath }&${QueryParams.ProductModel}=${ productModel }`
    case ApiUrl.SalesTextApi:
      return `${ApiUrl.SalesTextApi}?${QueryParams.PackagePath}=${ packagePath }&${QueryParams.ProductModel}=${productModel}&${QueryParams.Type}=${apiType}`
    default:
      return ''
  }
}

export function onlyGuardedVariables( sec: ISection ) {
  if ( sec.id === ESectionId.Guarded ) {
    return false;
  }
  for ( const v of sec.variables ) {
    if ( !v.isGuarded ) {
      return false;
    }
  }
  for ( const s of sec.sections ) {
    if ( !onlyGuardedVariables( s ) ) {
      return false;
    }
  }
  return true;
}

/**
 * Checks the type of the given variable
 * @param {object} variable the variable to check
 * @returns {string} the type of the given variable
 */
export const getVariableType = ( variable: IConfigurationVariable ) => {
  const hasValues = variable.values && variable.values.length > 0 && variable.values.every( value => value.name );
  const isSubModel = isSubmodelCountVariable( variable );

  if ( isSubModel ) {
    return EVariableType.Submodel;
  }
  if ( variable.valueType === 'Date' ) {
    return EVariableType.Date;
  }
  if ( variable.valueType === 'Number' ) {
    return EVariableType.Number;
  }
  if ( variable.allowMultipleAssignments ) {
    return EVariableType.Multiselect;
  }
  if ( hasValues && !variable.allowMultipleAssignments ) {
    return EVariableType.Singleselect;
  }
  return EVariableType.String;
}

/**
 * Flattens the sections hierarchy data
 * @param {IExtendedConfigureResponse} configuration the current configuration
 * @returns {object[]} a flattened list of sections for the current configuration
 */
export function getFlatSections( configuration: IExtendedConfigureResponse ): ISection[] {
  const viewIdSuffix = getViewIdSuffix();
  if ( !configuration?.data?.packagePath || !viewIdSuffix ) {
    return [];
  }
  const flatSections: ISection[] = [];
  const addSectionsToFlatList = ( section: IConfiguration | ISection | undefined ): void => {
    if ( !section || !section.sections ) {
      return;
    }
    for ( const subSection of section.sections ) {
      flatSections.push( subSection );
      addSectionsToFlatList( subSection );
    }
  }
  addSectionsToFlatList( configuration.data );
  updateGuardedVariablesAndTypes( flatSections );
  assignPropertiesToSections( flatSections );
  setIsCompleteToSections( configuration );
  return flatSections;
}

/**
 * Add IsComplete Property to All Sections and IsConfigComplete Property at Root level
 * @param configuration 
 */

const setIsCompleteToSections = ( configuration: IExtendedConfigureResponse ) => {
  configuration?.data?.sections.forEach( ( section: ISection ) => {
    setIsCompleteToInnerSections( configuration, section );
  } )

  //Add IsConfigComplete Property at Root level
  if ( configuration.data ) {
    const viewIdSuffix = getViewIdSuffix();
    configuration.data.isConfigComplete = !configuration.data.sections
      .filter( sec => !sec.id.endsWith( viewIdSuffix ) )
      .some( section => section.isComplete === false );
  }
}

/**
 * Add IsComplete Property to Inner Sections
 * @param configuration 
 * @param section
 */

const setIsCompleteToInnerSections = ( configuration: IExtendedConfigureResponse, section: ISection ) => {
  if ( !section || section.sections.length === 0 && section.variables.length === 0 ) {
    return;
  }

  const viewIdSuffix = getViewIdSuffix();

  //Checks if the Submodel is Assigned or not
  if ( section.id.endsWith( viewIdSuffix ) ) {
    const subModels = getSubModelsFromSectionId( section.id );
    if ( !isSubModelAssigned( configuration, subModels ) ) {
      section.isComplete = undefined;
      return;
    }
  }

  //Checks for UnAssigned Variables
  let unAssignedVar = [];
  if ( section.variables.length > 0 ) {
    unAssignedVar = section.variables.filter( ( variable ) => {
      return variable.complete === false && variable.isGuarded !== true && !variable.suppressed;
    } )
    section.isComplete = unAssignedVar.length > 0 ? false : true;
  }

  //Checks for UnAssigned Sections
  if ( section.sections.length > 0 ) {
    section.sections.forEach( ( subSection: ISection ) => {
      setIsCompleteToInnerSections( configuration, subSection );
    } )
    const unAssignedSec = section.sections.filter( sec => !sec.id.endsWith( viewIdSuffix ) ).filter( ( sec ) => {
      return sec.isComplete === false;
    } )
    section.isComplete = unAssignedVar.length > 0 || unAssignedSec.length > 0 ? false : true;
  }
}

/**
 * Checks if all sections of Root model are Complete including Submodel sections
 * @param section 
 * @returns true if all the Sections are complete else return false
 */
const isAllSectionsComplete = ( section: ISection ): boolean | undefined => {
  if ( !section || section.isComplete === undefined ) {
    return undefined;
  }
  if ( section.isComplete === false ) {
    return false;
  }
  let isComplete = true;
  if ( section.sections.length > 0 ) {
    for ( const subSection of section.sections ) {
      if ( isAllSectionsComplete( subSection ) === false ) {
        isComplete = false;
        break;
      }
    }
  }
  return isComplete;
}

/**
 * Check If the Configuration is Complete. In Submodel Case If it's assigned checks those sections as well else leave them
 * @param sections 
 * @returns true if the Configuration is complete else false
 */

export const isConfigurationComplete = ( sections: ISection[] ): boolean => {
  //loop through all sections and check if each section is complete or not
  if ( sections ) {
    for ( const section of sections ) {
      if ( isAllSectionsComplete( section ) === false ) {
        return false;
      }
    }
  }
  return true;
}

const updateGuardedVariablesAndTypes = ( sections: ISection[] ) => {
  sections.forEach( ( section: { variables: IConfigurationVariable[]; } ) => {
    section.variables = section.variables.filter( sec => sec.id !== ModelInclusion.Id );
    section.variables.forEach( v => setPropertiesToVariables( v ) );
  } )
}

const assignPropertiesToInnerSections = ( section: ISection ) => {
  if( section?.sections ) {
    assignPropertiesToSections( section.sections )
  }
  const suppressedVariables = section.variables.filter( ( variable ) => variable.suppressed )
  const isAllItemHdeOrSuppressed = section.variables.filter( ( variable ) => {
    return variable.suppressed || checkIsOptionHidden( variable )
  } )
  const suppressedInnerSectionVariables = section.sections.filter( innserSec => innserSec.suppressed );
  //check if all the variables of section are hidden or suppressed
  const isAllInnerItemHdeOrSuppressed = section.sections.filter( ( innerSec ) => {
    return innerSec.suppressed || innerSec?.isItemsAvailable
  } )
  section.suppressed = suppressedVariables.length === section.variables.length && suppressedInnerSectionVariables.length === section.sections.length;
  const unHideSections = section.variables.filter( ( variable ) => {
    return checkIsOptionHidden( variable );
  } )
  const unHideInnerSections = section.sections.filter( innerSec => innerSec?.isItemsAvailable
  )
  section.isItemsAvailable = ( unHideSections.length === section.variables.length || isAllItemHdeOrSuppressed.length === section.variables.length ) && ( unHideInnerSections.length === section.sections.length || isAllInnerItemHdeOrSuppressed.length === section.sections.length ) ? true : false;
}

const assignPropertiesToSections = ( sections: ISection[] ) => {
  sections.forEach( ( section: ISection ) => {
    if ( section.sections.length > 0 ) {
      assignPropertiesToInnerSections( section );
    } else {
      // adding suppressed property to sections
      const suppressedVariables = section.variables.filter( ( variable ) => variable.suppressed )
      //check if all the variables of section are hidden or suppressed
      const isAllItemHdeOrSuppressed = section.variables.filter( ( variable ) => {
        return variable.suppressed || checkIsOptionHidden( variable )
      } )
      section.suppressed = suppressedVariables.length === section.variables.length;

      // adding isItemsAvailable property to sections
      const unHideSections = section.variables.filter( ( variable ) => {
        return checkIsOptionHidden( variable );
      } )
      // Returns true if all the variables have HDE=true (or) if length of hidden variables & suppressed variables equals to total number of variables in a section
      section.isItemsAvailable = unHideSections.length === section.variables.length || isAllItemHdeOrSuppressed.length === section.variables.length ? true : false;
    }
  } )
}


const createGaurdedSection = ( sections: ISection[] ) => {
  const guardedVariables: IConfigurationVariable[] = [];
  if ( sections.length > 0 ) {
    getGuardedVariables( sections, guardedVariables )
  }
  if ( guardedVariables.length > 0 ) {
    sections.push( { 'id': ESectionId.Guarded, 'name': 'Guarded', sections: [], 'variables': guardedVariables, 'isComplete': true, children: [] } )
  }
}

//This returns the guarded variables from the section and sub sections
function getGuardedVariables( sections:ISection[], guardedVariables:IConfigurationVariable[] ):void {
  sections.forEach( section => {
    if( section?.sections.length > 0 ) {
      getGuardedVariables( section.sections, guardedVariables );
    }
    section?.variables?.forEach( ( vrbl: IConfigurationVariable ) => {
      if ( vrbl.variableType !== EVariableType.Submodel && vrbl.isGuarded ) {
        guardedVariables.push( vrbl );
      }
    } );
  } )
}

/**
 * Checks the section for the currently selected model
 * @param {ISection[]} flatSections extracted from the configuration
 * @param {string[]} parents array of parents productId 
 * @param {string}currentModel productId  of current model
 * @returns {object[]} a filtered list of flat section for the currently selected model
 */
export const getModelSections = ( flatSections: ISection[], parents: string[], currentModel: string ) => {
  return flatSections.filter( s => {
    //remove scope section and other unwanted sections from the selectable sections
    if ( !s.id || isScope( s ) || !isSection( s ) ) {
      return false;
    }
    const sectionPathParts = s.id?.split( '.' ) || [];
    const view = [...sectionPathParts].reverse().find( sectionPathPart => sectionPathPart.includes( getViewIdSuffix() ) ) as string;
    //  To resolve inconsistent behavior while loading configureResponse
    const lastView = view ? view : ''
    const isSubSection = s.id?.substring( s.id?.indexOf( lastView ) + lastView.length + 1 ).split( '.' ).length > 1;
    if ( lastView?.includes( currentModel ) &&
      !s.id?.endsWith( lastView ) &&
      !isSubSection &&
      parents.every( p => s.id?.includes( p ) ) &&
      s.id?.split( getViewIdSuffix() ).length === parents.length + 2 ) {
      ///section is selectable if last view is for current selected model and
      ///section is not parent section for submodel and
      ///section is not a subsection of and
      ///section id includes every parent id and
      ///section id does not have any other parent id
      return true;
    }

    return false;
  } );
}
/**
 * Checks the section for the currently selected model
 * @param {IExtendedConfigureResponse} configuration the configuration to check
 * @returns {ISelectableSectionsReturnType} a filtered list of section for the currently selected model and whether selected view is available for that model
 */
export function getSelectableSections( configuration: IExtendedConfigureResponse, path?:string ): ISelectableSectionsReturnType {
  if ( !configuration?.data?.packagePath || !configuration.savedConfiguration ) {
    return { sections: [] as ISection[], viewUnavailable: false };
  }

  const { rootModel } = configuration.savedConfiguration.modelContext;
  const modelPath = path ? path : UrlHelper.getSearchParameter( 'model' );
  const modelPathParts = modelPath ? [rootModel.id, ...modelPath.split( '.' )] : [rootModel.id];
  const currentModel = modelPath ? modelPathParts[modelPathParts.length - 1] : rootModel.id;
  const parents = modelPath ? [...modelPathParts.slice( 0, modelPathParts.length - 1 )] : [];
  const flatSections = getFlatSections( configuration );
  const viewUnavailable = currentModel !== rootModel.id ? !flatSections.some( ( sec ) => sec.id.match( new RegExp( `${currentModel}${getViewIdSuffix()}$` ) ) ) : false;
  const sections = getModelSections( flatSections, parents, currentModel );

  // find isGuarded variables from all sections and create new array of guarded varaibles to display in guarded section
  const config = AppStore.getState().configuration as IExtendedConfigureResponse

  //if model is servicerelevant model do isGuarded related operation. For product model isGuarded is not applicable
  if ( config?.savedConfiguration?.isServiceConfiguration ) {
    createGaurdedSection( sections );
  }
  const searchCount = { searchCount: 0 };
  const searchMatches = [];
  traverseSearchValueOnSections( sections, searchCount, searchMatches )

  return { sections: sections.filter( ( sec ) => !onlyGuardedVariables( sec ) ), viewUnavailable, searchCount, searchMatches };
}

function setSearchMatches( searchProps, variable, searchMatchKey ) {
  const { config, section, searchMatches, searchCount } = searchProps;
  //Validating the variables belongs to Guarded section and other sections
  const isValidVariable = section.id === ESectionId.Guarded || !variable.isGuarded;
  if ( config.productSearchValue !== '' && isValidVariable) {
    variable.isSearchValueMatched = true;
    section.isSearchValueMatched = true;
    searchCount.searchCount = searchCount.searchCount + 1;
  }

  //Pushing variables by segregating guarded and non guarded sections
  if(isValidVariable)
  {
    const index = searchMatches.findIndex((s : ISearchOption) => s.title === searchMatchKey.title);
    if(index == -1)
    {
      searchMatches.push( searchMatchKey )
    }
  }
}

function getSearchMatchesWithName( nameProps, variable, searchMatchKey, searchOn, featureType, parentVariable? ) {
  const { config, section } = nameProps;
  // checkIsOptionServiceRelevant removed this condition for search BUG:2984162;
  if ( config.onChangeProductSearchValue !== '' && checkIsOptionHidden( variable ) === false && variable[searchOn].toLowerCase().includes( config.onChangeProductSearchValue.toLowerCase() ) ) {
    if ( featureType === 'family' ) {
      setSearchMatches( nameProps, variable, searchMatchKey )
    }
    if ( featureType === 'feature' && parentVariable.isGuarded === undefined ) {
      setSearchMatches( nameProps, variable, searchMatchKey )
    }
  }
  if ( config.onChangeProductSearchValue !== '' && checkIsOptionHidden( variable ) === false && variable[searchOn].toLowerCase().includes( config.onChangeProductSearchValue.toLowerCase() ) && section.name === 'Guarded' ) {
    if ( featureType === 'family' && variable.isGuarded ) {
      setSearchMatches( nameProps, variable, searchMatchKey )
    }
    if ( featureType === 'feature' && parentVariable.isGuarded ) {
      setSearchMatches( nameProps, variable, searchMatchKey )
    }
  }
}

const searchMatch = ( searchMatchProps: any ) => {
  const { config, variable, userSettings, idKey, featureType, props, searchMatchKey, parentVariable } = searchMatchProps;
  // checkIsOptionServiceRelevant removed this condition for search BUG:2984162;
  if ( config.onChangeProductSearchValue !== '' && checkIsOptionHidden( variable ) === false && userSettings.showCode && variable[idKey].toLowerCase().includes( config.onChangeProductSearchValue.toLowerCase().indexOf( '-' ) !== -1 ? config.onChangeProductSearchValue.toLowerCase().substring( 0, config.onChangeProductSearchValue.toLowerCase().indexOf( '-' ) ) : config.onChangeProductSearchValue.toLowerCase() ) ) {
    if ( featureType === 'family' ) {
      setSearchMatches( props, variable, searchMatchKey )
    }
    if ( featureType === 'feature' && parentVariable.isGuarded === undefined ) {
      setSearchMatches( props, variable, searchMatchKey )
    }
  }
}

function getSearchMatchesWithId( props, variable, searchMatchKey, idKey, featureType, parentVariable? ) {
  const { config, userSettings, section } = props;
  searchMatch( { config, variable, userSettings, idKey, featureType, props, searchMatchKey, parentVariable } )
  if ( config.onChangeProductSearchValue !== '' && checkIsOptionHidden( variable ) === false && userSettings.showCode && variable[idKey].toLowerCase().includes( config.onChangeProductSearchValue.toLowerCase().indexOf( '-' ) !== -1 ? config.onChangeProductSearchValue.toLowerCase().substring( 0, config.onChangeProductSearchValue.toLowerCase().indexOf( '-' ) ) : config.onChangeProductSearchValue.toLowerCase() ) && section.name === 'Guarded' ) {
    if ( featureType === 'family' && variable.isGuarded ) {
      setSearchMatches( props, variable, searchMatchKey )
    }
    if ( featureType === 'feature' && parentVariable.isGuarded ) {
      setSearchMatches( props, variable, searchMatchKey )
    }
  }
}

function traverseSearchValueOnSections( sections: ISection[], searchCount, searchMatches ) {
  const config = AppStore.getState().configuration as IExtendedConfigureResponse
  const userSettings = AppStore.getState().userSettings;
  sections.forEach( ( section: { variables: IConfigurationVariable[]; } ) => {
    section.isSearchValueMatched = false;
    if ( section.sections.length > 0 ) {
      section.sections.forEach( innerSection => {
        innerSection.variables.forEach( innerSectionV => {
          innerSectionV.isSearchValueMatched = false;
          const searchOn = innerSectionV.shortSalesText ? 'shortSalesText' : 'name';
          if ( checkIsVariableSuppressed( innerSectionV ) === false ) {
            getSearchMatchesWithName( { config, section, searchMatches, searchCount }, innerSectionV, { title: innerSectionV[searchOn], familyName: '' }, searchOn, 'family' )
            getSearchMatchesWithId( { config, section, userSettings, searchMatches, searchCount }, innerSectionV, { title: innerSectionV.id + '-' + innerSectionV[searchOn], familyName: '', displayName:getNameWithCode( innerSectionV ) }, 'id', 'family' )
          }
        } )
      } )
    }
    findSearchValueInVariables( section, searchCount, searchMatches, config, userSettings )
  } )
}

function findSearchValueInVariables( section: ISection[], searchCount, searchMatches, config, userSettings ) {
  section.variables.forEach( variable => {
    variable.isSearchValueMatched = false;
    const searchOn = variable.shortSalesText ? 'shortSalesText' : 'name';
    if ( checkIsVariableSuppressed( variable ) === false ) {
      getSearchMatchesWithName( { config, section, searchMatches, searchCount }, variable, { title: variable[searchOn], familyName: '' }, searchOn, 'family' )
      getSearchMatchesWithId( { config, section, userSettings, searchMatches, searchCount }, variable, { title: variable.id + '-' + variable[searchOn], familyName: '', displayName:getNameWithCode( variable ) }, 'id', 'family' )
    }

    findSearchValueInVariablesValues( variable, section, config, userSettings, searchCount, searchMatches, searchOn )
  } )
}

const findSearchValueInVariablesValues = ( variable: IConfigurationVariable, section: ISection[], config: IExtendedConfigureResponse, userSettings, searchCount: number, searchMatches: { title: string, familyName: string }[], searchOnOfFamily: string ) => {
  variable.values.forEach( val => {
    val.isSearchValueMatched = false;
    const searchOn = val.shortSalesText ? 'shortSalesText' : 'name';
    if ( variable.variableType === EVariableType.Multiselect || variable.variableType === EVariableType.Singleselect && checkIsOptionHidden( variable ) === false ) {
      getSearchMatchesWithName( { config, section, searchMatches, searchCount }, val, { title: val[searchOn], familyName: variable[searchOnOfFamily] }, searchOn, 'feature', variable )
      getSearchMatchesWithId( { config, section, userSettings, searchMatches, searchCount }, val, { title: val.value + '-' + val[searchOn], familyName: variable[searchOnOfFamily], displayName:getNameWithCode( val ) }, 'value', 'feature', variable )
    }
  } )

}

function getVariableFromSection( variableId: string, section: ISection ): IConfigurationVariable | null {
  if ( section.variables ) {
    const variable = section.variables.find( v => v.id === variableId );
    if ( variable ) {
      return variable;
    }
  }

  if ( section.sections ) {
    for ( const subSection of section.sections ) {
      const result = getVariableFromSection( variableId, subSection );
      if ( result ) {
        return result;
      }
    }
  }
  return null;
}

export function getLastAssignmentVariable( configuration: IExtendedConfigureResponse ): IConfigurationVariable | null {
  if ( !configuration?.data?.sections || !configuration.lastChange ) {
    return null;
  }

  return getVariableFromAllSections( configuration, configuration.lastChange.variableId );
}

/**
 * 
 * @param configuration ConfigurationState
 * @param variableId Variable Id
 * @returns {IConfigurationVariable} Loops through all sections of Configure response and returns the Variable
 */
export function getVariableFromAllSections( configuration: IExtendedConfigureResponse, variableId: string ): IConfigurationVariable | null {
  for ( const section of configuration.data.sections ) {
    const result = getVariableFromSection( variableId, section );
    if ( result ) {
      return result;
    }
  }
  return null;
}
/**
 * To get the name with or without value of a feature/family based on showCode
 * @param {IFeatureOrFamily} feature the feature/family
 * @param {boolean} showCode  the user preference for showCode
 * @param {type} type  the page type quick/detailed summary
 * @returns {string} the name with or without value of a feature/family based on showCode
 */
export function getNameWithCode( feature: IFeatureOrFamily | IConfigurationVariable, showCode = true, type?: string ):string {
  const id = getFeatureCode( feature.id, feature.variableType );
  const featureName = appSettings.UseShortSalesText && feature.shortSalesText ? feature.shortSalesText : feature.name;
  const variableName = type ? `${id || feature.value} - ${featureName}` : `${featureName} (${id || feature.value})`;
  return showCode
    ? `${variableName}`
    : `${featureName}`
}

/**
 * To get the id  without internal code for features or submodels
 * @param {string } variableId the variable id
 * @param {string } variableType the variable type
 * @returns {string} the name with or without value of a feature/family based on showCode
 */
export function getFeatureCode( variableId:string , variableType:string ):string {
  let id = variableId;
  //To get the actual feature instead of whole heirarchy
  //Ex: id = I_867030.867043 ---> 867043
  id = id?.substring( id.lastIndexOf( '.' ) + 1 )
  if ( variableType === EVariableType.Submodel ) {
    //When it's a submodel, remove the last two letters for the count variable
    //Ex: 866060_C ---> 866060
    id = id ? id.slice( 0, -2 ) : id;
  }
  return id;
}

export function getViewId(): string {
  const viewId = SessionStore.get( ESessionStore.ViewId )
  const config = AppStore.getState().configuration as IExtendedConfigureResponse
  const defaultView = config?.viewIds ? config?.viewIds[0] : null
  if ( viewId ) {
    // if viewId already set
    return viewId
  } else if ( defaultView ) {
    // to set default viewId if available
    SessionStore.set( ESessionStore.ViewId, defaultView )
    return defaultView
  } else if ( config.viewIds && config.viewIds.length > 0 ) {
    // setting the first available view if default view not available
    SessionStore.set( ESessionStore.ViewId, config.viewIds[0] )
    return config.viewIds[0]
  } else {
    return 'system'
  }
}

export function getPackagePath( productId: string | undefined ) {
  if ( !productId ) {
    return null;
  }
  return `C${productId}/R${productId}`;
}

export function getSubModelsFromSectionId( sectionId: string ) {
  return sectionId.split( '.' )
    .filter( sIdPart => sIdPart.endsWith( getViewIdSuffix() ) )
    .slice( 1 )
    .map( sIdPart => sIdPart.replace( getViewIdSuffix(), '' ) );
}

/**
 * Checks if there is a conflict based on the current assignments and the configure response
 * @param {object} configuration the configure response
 * @param {object[]} assignments the current assignment
 * @returns {object} the conflict object or null if there is no conflict
 */
export function getConflict( configuration: IExtendedConfigureResponse ) {
  const removedAssignments = configuration.data?.removedAssignments?.variableAssignments;

  if ( !removedAssignments || !removedAssignments.length ) {
    return null;
  }

  const filteredRemovedAssignments = [];

  for ( const removedAssignment of removedAssignments ) {
    const subModels = removedAssignment.variable.id
      .split( '.' )
      .filter( ( idPart: string ) => idPart.startsWith( 'I_' ) && idPart.match( /_\d+$/ ) )
      .map( ( submodelInstance: string ) => submodelInstance.replace( 'I_', '' ).replace( /_\d+$/, '' ) )
      .filter( ( m ) => configuration.contextData?.models && m in configuration.contextData.models );
    const removedEmptyAssignment = !configuration.assignments || !configuration.assignments.length
      ? null
      : configuration.assignments.find( a => a.variableId === removedAssignment.variable.id && !a.value && removedAssignment.value?.value === a.value );

    if ( !isSubModelAssigned( configuration, subModels ) || removedEmptyAssignment || removedAssignment.variable.id.endsWith( ModelInclusion.Id ) ) {
      /**
       * no conflict if removed assignment is in not assigned submodel
       * no conflict if assignment was empty and is removed
       * no conflict for modelInclusion variables
       */
      continue;
    }

    filteredRemovedAssignments.push( removedAssignment );
  }

  //conflict appears when previous assignments have to be removed
  return filteredRemovedAssignments.length === 0
    ? null
    : {
      assignmentsToRemove: createConflicts( configuration, filteredRemovedAssignments ),
      assignmentsBefore: configuration.assignments ? [...configuration.assignments] : []
    };
}

/**
 * Creates a hierarchical object based on model structure with conflicts
 * @param {IExtendedConfigureResponse} configuration the current configure state
 * @param {IVariableAssignment[]} removedAssignments the removed assignments
 * @returns {object} the conflict object
 */
const createConflicts = ( configuration: IExtendedConfigureResponse, removedAssignments: IVariableAssignment[] ) => {
  const result: IConflictHierarchy = {
    conflicts: []
  };
  for ( const removedAssignment of removedAssignments ) {
    const variableIdParts = removedAssignment.variable.id.split( '.' );
    const subModels = variableIdParts.filter( ( idPart: string ) => idPart.startsWith( 'I_' ) && idPart.match( /_\d+$/ ) )
      .map( ( submodelInstance: string ) => submodelInstance.replace( 'I_', '' ).replace( /_\d+$/, '' ) )
      .filter( ( m ) => configuration.contextData?.models && m in configuration.contextData.models );
    let a = result;

    for ( const subModel of subModels ) {
      const subModelName = subModel.replace( 'I_', '' ).replace( /_\d+$/, '' );
      if ( !a[subModelName] ) {
        a[subModelName] = {
          conflicts: []
        };
      }
      a = a[subModelName] as IConflictHierarchy;
    }
    a.conflicts?.push( removedAssignment );
  }
  return result;
}

/**
 * Creates a string with information about which value is assigned to which variable
 * @param {INamed} variable the variable object
 * @param {IConfigurationValue | IVariableValueAssignment | IValueType} value the value object
 * @param {boolean} showCode to get the user preference
 * @param {boolean} isAssignment indicates if the value was assigend to a variable or unassigned from a variable
 * @returns {string} the string with information about which value is assigned to which variable
 */
export const getConflictListItemText = ( variable: INamedWOCode | null, value: IConfigurationValue | IVariableValueAssignment | IValueType, showCode = true, isAssignment = true ) => {
  const variableName = getNameWithCode( variable as IFeatureOrFamily, showCode );
  let valueName;

  if ( typeof value === 'string' && value ) {
    valueName = value;
  } else if ( typeof value === 'object' && value?.name && value?.value ) {
    const valueObj = {
      name: value.name,
      value: value.value,
      shortSalesText: value.shortSalesText
    };
    valueName = getNameWithCode( valueObj as IFeatureOrFamily, showCode );
  } else {
    valueName = '\'\'';
  }

  return isAssignment
    ? ` ${variableName} ${t( 'labels.to' )} ${valueName}`
    : ` ${valueName} ${t( 'labels.from' )} ${variableName}`;
}

/**
 * Checks if all submodel variables are activated (COUNT variable set to 1 or higher)
 * @param {IExtendedConfigureResponse} configuration the configuration object
 * @param {string[]} subModels an array of the submodel path
 * @returns {boolean} true if all submodels are active, else false
 */
export function isSubModelAssigned( configuration: IExtendedConfigureResponse, subModels: string[] ) {
  const countVariables = configuration.contextData?.countVariables || {};
  let previousPath = '';
  for ( const subModel of subModels ) {
    const varPath = `${previousPath}${subModel}${IdFormat.Suffix.SubmodelId}`;
    const assignment = countVariables[varPath]?.values.find( v => v.state.isAssigned );
    if ( !assignment || Number( assignment.value ) < 1 ) {
      return false;
    }
    previousPath += `I_${subModel}.`;
  }
  return true;
}

const getCurrentAssignments = ( assignments: IAssignment[] | null ) => {
  if ( assignments ) {
    return assignments.map( element => {
      if ( element && element.variableId === ModelInclusion.Id ) {
        element.priority = PriorityValue.High;
      } else if ( element && element.variableId === ProductScope.MarketId ) {
        element.priority = PriorityValue.Medium;
      } else if ( element && element.variableId === ProductScope.BuildDateId ) {
        element.priority = PriorityValue.Medium;
      }
      return element
    } );
  }
  return []
}

function createNewAssignment( variable: IConfigurationVariable, configuration: IExtendedConfigureResponse, isVariableSubmodel: boolean, value: IValueType, variablePriority: number ) {
  const newAssignment: IAssignment = { assignmentType: 'Singleton', variableId: variable.id, value, exclude: false };
  if ( configuration.savedConfiguration.isServiceConfiguration || isVariableSubmodel ) {
    newAssignment['priority'] = variablePriority;
  }
  return newAssignment
}

const handleEmptyAssignments = ( currentAssignments: IAssignment[], variable: IConfigurationVariable, value: IValueType ) => {
  if ( ( variable.variableType === EVariableType.Number || variable.variableType === EVariableType.Submodel ) && Number( value ) === 0 ) {
    return currentAssignments.filter( a => a.variableId !== variable.id )
  }
  return currentAssignments;
}

//This function returns the assignment if available in the current assignments array
function findCurrentAssignments ( currentAssignments: IAssignment[], variable: IConfigurationVariable, value: IValueType ):IAssignment|undefined {
  return currentAssignments.find( a => a.variableId === variable.id && a.value === value );
}

//This function returns the boolean value based on the input parameters
function hasValidPriority ( variablePriority: number, isVariableSubmodel: boolean ):boolean {
  return variablePriority > 0 || isVariableSubmodel
}


/**
 * Creates the request object with the latest assignment
 * @param {IExtendedConfigureResponse} configuration the configuration object
 * @param {object} variable the variable which was changed
 * @param {string|number|boolean|undefined} value the new value for the variable
 * @param {object} lastChange the last change object which contains additional information about the latest change -> will be updated based on the change
 * @returns {object} the request object with the updated assignments
 */
export function onVariableChange( configuration: IExtendedConfigureResponse, variable: IConfigurationVariable, value: IValueType, lastChange: ILastChange ) {
  let currentAssignments: IAssignment[] = getCurrentAssignments( configuration.assignments )
  let variablePriority = 0
  const isVariableSubmodel = variable.id.endsWith( IdFormat.Suffix.SubmodelId );
  const config = AppStore.getState().configuration as IExtendedConfigureResponse
  variablePriority = getPriority( variable, isVariableSubmodel, value, config.savedConfiguration.isServiceConfiguration )
  const assignment = currentAssignments.find( a => a.variableId === variable.id );
  if ( findCurrentAssignments ( currentAssignments, variable, value ) ) {
    //same assignment found -> remove assignment
    currentAssignments = currentAssignments.filter( a => a.variableId !== variable.id || a.value !== value );
    lastChange.type = EChangeType.Remove;
  } else if ( !variable.allowMultipleAssignments && assignment ) {
    //found another assignment for a variable which does not allow multi value -> change value of assignment
    lastChange.valueBefore = assignment.value;
    lastChange.type = EChangeType.Change;
    assignment.value = value;
    if ( hasValidPriority( variablePriority, isVariableSubmodel ) ) {
      assignment.priority = variablePriority;
    }
    currentAssignments = handleEmptyAssignments( currentAssignments, variable, value );
    if ( currentAssignments.findIndex( ca => ca.variableId === assignment.variableId ) >= 0 ) {
      const newAssignment: IAssignment = createNewAssignment( variable, configuration, isVariableSubmodel, value, variablePriority )
      lastChange.priority = currentAssignments.find( ca => ca.variableId === newAssignment.variableId )?.priority;
      currentAssignments = currentAssignments.filter( a => a.variableId !== variable.id );
      currentAssignments.push( newAssignment );
    }
  } else if ( value !== '' ) {
    //no assignment found or variable allows multivalue -> add new assignment
    const newAssignment: IAssignment = createNewAssignment( variable, configuration, isVariableSubmodel, value, variablePriority )
    currentAssignments.push( newAssignment );
    lastChange.type = EChangeType.Add;
    if ( currentAssignments.findIndex( ca => ca.variableId === newAssignment.variableId ) >= 0 ) {
      lastChange.priority = currentAssignments.find( ca => ca.variableId === newAssignment.variableId )?.priority;
    }
  } else {
    const ruleAssignment = checkAssignedByRule( configuration, variable, isVariableSubmodel, lastChange )
    if ( ruleAssignment ) {
      currentAssignments.push( ruleAssignment );
      lastChange.type = EChangeType.Change;
    } else {
      return null;
    }
  }
  currentAssignments = assignPrioritiesToAssigments( currentAssignments );
  return createConfigureRequest( configuration.savedConfiguration.modelContext.rootModel.id, configuration.configurationId, currentAssignments );
}

export const assignPrioritiesToAssigments = ( currentAssignments: IAssignment[] ) => {
  // filter assignments with Model_Inclusion,Scope, etc
  const otherCurrentAssignments = currentAssignments.filter( currAss => currAss?.priority && currAss?.priority > 995 );
  // filter assignments to assign priority on selection & removal
  const leftCurrentAssignments = currentAssignments.filter( currAss => currAss?.priority === undefined || currAss?.priority < 995 );
  //Set priority based on the Feature 2892905 to take the user selection as priority and update the configuration and show the conflicts
  leftCurrentAssignments.forEach( ( curAss, index ) => {
    if ( index === leftCurrentAssignments.length - 1 ) {
      curAss.priority = leftCurrentAssignments.length;
    } else {
      curAss.priority = leftCurrentAssignments.length - 1 - index;
    }
  } );
  // Merge both filtered assignments array, filter out the 
  return [...otherCurrentAssignments, ...leftCurrentAssignments]
}

/**
 * Updated the isGuarded property of variable by checking IsServiceRelevant property and checking if it is subModel
 * @param {object} variable the variable which was changed
 * @returns {null} updates the isGuarded property of the feature Family 
 */
export const setPropertiesToVariables = ( variable: IConfigurationVariable ): void => {
  const config = AppStore.getState().configuration as IExtendedConfigureResponse
  const variableType = getVariableType( variable );
  variable.variableType = variableType;
  const variableId = variable.id.split( '.' ).pop();
  // To assign shortSalesText data to the feature families
  let featureCode = variableId;
  if ( variableType === EVariableType.Submodel ) {
    featureCode = variable.id.split( '.' ).pop()?.split( '_' )[0];
  }
  variable.shortSalesText = appSettings.UseShortSalesText && featureCode && config.shortSalesText && config.shortSalesText[featureCode] ? config.shortSalesText[featureCode].text : null;
  variable.price = config.price && variableId && config.price[variableId] ? config.price[variableId].price : null;
  if ( config.bundledFeatures[variable.id] ) {
    variable.price = 0;
    variable.isBundled = true;
  }

  if ( config?.savedConfiguration?.isServiceConfiguration ) {
    const isSubModel = variable.id.match( 'COUNT' )
    const serviceRelevant = variable?.properties?.find( _ => _.id === EPDMProperty.IsServiceRelevant )
    if ( serviceRelevant && serviceRelevant?.value ) {
      variable.isGuarded = !isSubModel
    }
  }

  if ( variableType === EVariableType.Multiselect || variableType === EVariableType.Singleselect ) {
    setPropertiesToSingleAndMultiVariables( variable, variableType, config );
  }
  setSuppressedToVariables( variable );
  setIsCompleteToVariables( variable );
}

/**
 * This function will set complete property to variable based on Variable Assignment
 * Sets True if variable is assigned else false
 * @param variable 
 */
const setIsCompleteToVariables = ( variable: IConfigurationVariable ) => {
  if ( variable.variableType === EVariableType.String ||
    variable?.variableType === EVariableType.Number || 
    variable?.variableType === EVariableType.Submodel ) {
    const assigned = variable.values?.find( v => v.state.isAssigned );
    variable.complete = assigned ? true : false;
  }
}

const setPropertiesToSingleAndMultiVariables = ( variable: IConfigurationVariable, variableType: string, config: IExtendedConfigureResponse ) => {
  variable.values.forEach( val => {
    val.variableType = variableType;
    val.price = config.price && config.price[val.value.toString()] ? config.price[val.value.toString()].price : null;
    if ( config.bundledFeatures[val.value.toString()] ) {
      val.price = 0;
      val.isBundled = true;
    }
    // To assign shortSalesText data to the features of SVFF & MVFF
    val.shortSalesText = appSettings.UseShortSalesText && config.shortSalesText && config.shortSalesText[val.value.toString()] ? config.shortSalesText[val.value.toString()].text : null;
  } )
  // To update the Hide property for the variable/featureFamily
  setHDEToVariables( variable );
}

//Add the suppressed value based valid or invalid variable 
const setSuppressedToVariables = ( variable: IConfigurationVariable ) => {
  variable.suppressed = !isFeatureValidByMarketAvailabilty( variable );
}

const setHDEToVariables = ( variable: IConfigurationVariable ) => {
  if ( !checkIsOptionHidden( variable ) ) {
    const unHideVariables = variable.values.filter( ( val ) => {
      return checkIsOptionHidden( val );
    } )
    const hasHDEProperty = variable.properties?.find( ( property: IConfigurationProperty ) => property.id === EConfigItProperty.Hide );
    if ( hasHDEProperty ) {
      hasHDEProperty.value = unHideVariables.length === variable.values.length ? true : false;
    } else {
      variable.properties = [...variable.properties, {
        'id': 'HDE',
        'value': unHideVariables.length === variable.values.length ? true : false,
        'type': 'Boolean'
      }]
    }
  }
}

/**
 * gets the priority of variable by checking IsServiceRelevant property of variable and value
 * @param {object} variable the variable which was changed
 * @param {object} isVariableSubmodel the variable is submodel or not
 * @param {object} value the value of variable
 * @param {object} isServiceConfiguration the value of isServiceConfiguration true or false
 * @returns {number} returns 999 or 0 based on IsServiceRelevant flag of variable and selected value 
 */
export const getPriority = ( variable: IConfigurationVariable, isVariableSubmodel: boolean, value: IValueType, isServiceConfiguration: boolean | undefined ) => {
  const serviceRelevant = variable?.properties?.find( _ => _.id === EPDMProperty.IsServiceRelevant )
  let priority = 0;
  if ( isVariableSubmodel && value && value as number >= 1 ) {
    priority = PriorityValue.High;
  } else if ( isServiceConfiguration && serviceRelevant && serviceRelevant.value ) {
    priority = PriorityValue.Normal;
  }
  return priority;
}

/**
 * Reverts the latest changes and returns the request object
 * @param {IExtendedConfigureResponse} configuration the configuration object
 * @returns {object} the request object with the reverted assignments
 */
export function onRevertAssignment( configuration: IExtendedConfigureResponse ) {
  const { configurationId, conflict, lastChange, savedConfiguration } = configuration;

  if ( !configurationId || !conflict || !lastChange || !savedConfiguration ) {
    return null;
  }

  let newAssignments = [...conflict.assignmentsBefore];
  const assignment = newAssignments.find( a => a.variableId === lastChange.variableId );

  switch ( lastChange.type ) {
    case EChangeType.Remove:
      //assignment was removed -> add assignment again
      newAssignments.push( {
        assignmentType: 'Singleton',
        variableId: lastChange.variableId,
        value: lastChange.value,
        exclude: false,
        priority: lastChange.priority
      } );
      break;
    case EChangeType.Change:
      //assignment was changed -> assign previous value
      if ( !assignment ) {
        throw new Error( 'assignment is not defined' );
      }
      assignment.value = lastChange.valueBefore;
      break;
    case EChangeType.Add:
      //assignment was added -> remove assignment again
      newAssignments = newAssignments.filter( a => !( a.variableId === lastChange.variableId && a.value === lastChange.value ) );
      break;
    default:
      throw new Error( `change type '${lastChange.type}' is not supported` );
  }
  return createConfigureRequest( savedConfiguration.modelContext.rootModel.id, configurationId, newAssignments );
}

/**
 * Creates the request object for the configure endpoint call
 * @param {string} productId the product id
 * @param {string} configurationId the configuration id
 * @param {IAssignment[] | null} variableAssignments the current assignments
 * @returns {object} the configure request object
 */
export function createConfigureRequest( productId: string, configurationId: string | null, variableAssignments: IAssignment[] | null ): IConfigureRequest {
  const view: string = getViewId();
  const langCode = getLanguageCode();
  const configureSetting: IConfigureRequest['settings'] = {
    debug: false,
    includePriceLines: false,
    phaseBehavior: 'InSections',
    assignmentResolutionOrder: 'ByPriority'
  }
  const currency = JSON.parse( JSON.stringify( AppStore.getState().applicationSettings ) ).currency
  return {
    configureRequest: {
      configurationId: configurationId || '',
      globalArguments: {
        SalesArea: {
          SalesOrganization: '0001',
          DistributionChannel: '01',
          SalesDivision: '01'
        }
      },
      viewId: view,
      line: {
        quantity: {
          unit: 'EA',
          value: 1
        },
        productId: productId,
        priceLineAssignments: [],
        variableAssignments: variableAssignments || [],
        arguments: {},
        sublines: []
      },
      settings: configureSetting,
      currency: currency.countryCode ? currency.countryCode : 'EUR',
      date: '2021-09-15T10:53:44.173Z',
      language: langCode
    }
  };
}

/**
 * Creates the request object for the configure endpoint call
 * @param {string} configuration the configuration
 * @returns {object} the configure request object
 */
export function getConfigureRequest(
  configuration: IExtendedConfigureResponse, removeConflicts: boolean = false ) {
  //If Conflicts exists, remove the conflicted assignments from configure request object
  if ( removeConflicts ) {
    configuration.conflict?.assignmentsToRemove.conflicts.forEach( v => {
      const index = configuration.assignments?.findIndex( assignment => assignment.variableId == v.variable.id && assignment.value == v.value.value );
      if ( index && index >= 0 ) {
        configuration.assignments?.splice( index, 1 );
      }
    } );
  }
  const currentAssignments = configuration.assignments ? [...configuration.assignments] : [];
  return createConfigureRequest( configuration.savedConfiguration.modelContext.rootModel.id, configuration.configurationId, currentAssignments );
}

/**
 * Returns the model id of the submodel
 * @param {string} id the complete id of the submodel section
 * @returns {string|undefined} the model id of the submodel if the section is a submodel
 */
export const getSubmodelIdFromSectionId = ( id: string ) => {
  if ( !id.endsWith( getViewIdSuffix() ) ) {
    return '';
  }
  return id?.split( '.' )?.pop()?.replace( getViewIdSuffix(), '' ) || '';
}

/**
 * Returns a string representing the current discontinued assignments for the variable 
 * @param {IVariableValueAssignment[]} values the values that are currenlty removed from assignment for the variable
 * @param {boolean} isMulti flag to check if variable is multivalued
 * @param {boolean} showCode to ckeck if variable is string or number or date
 * @returns {string[]|undefined} string represnting removed assignment values seperated by commas if variable is mulivalued
 */
export const getRemovedValues = ( values: IVariableValueAssignment[], isMulti?: boolean, showCode?: boolean ) => values.filter( ( value: IVariableValueAssignment ) => !isMulti || !value.exclude ).map( ( value: IVariableValueAssignment ) => getNameWithCode( value as IFeatureOrFamily, showCode ) );

/**
 * Returns a string representing the current changes for the variable 
 * @param {IVariableValueAssignment[]} values the values that are currenlty removed from assignment for the variable
 * @param {boolean} isMulti flag to check if variable is multivalued
 * @param {number} changeType integer indicating the type of change
 * @param {boolean} showCode the user preference for showCode
 * @param {IConfigurationValue[]} assigned the values that will be assigned to the variable
 * @returns {string[]|undefined} string represnting removed assignment values seperated by commas if variable is mulivalued
 */
export const getChanges = ( values: IVariableValueAssignment[], isMulti?: boolean, changeType?: number, showCode?: boolean, assigned?: IConfigurationValue[] ) => {
  const removed: IVariableValueAssignment[] = values.filter( ( value: IVariableValueAssignment ) => !isMulti || !value.exclude );
  const ct: number = changeType === 1 || changeType === 2 ? changeType : 0;
  const connectingStatement = ['unassigned', 'cleared', 'changed'].map( ( key: string ) => t( 'configurationValidationDialog.getChanges.' + key ) );
  const res: string[] = [
    removed.map( ( value: IVariableValueAssignment ) => getNameWithCode( value as IFeatureOrFamily, showCode ) ).join( ',' ) + ' ' || '',
    ( removed.length > 1 ? 'are ' : 'is ' ) + connectingStatement[ct]
  ];
  if ( changeType === 2 ) {
    res.push(
      ' ' + assigned?.map( ( value: IConfigurationValue ) =>
        getNameWithCode( value as IFeatureOrFamily, showCode )
      ).join( ',' ) || ''
    )
  }
  return res;
}
/**
 * @returns {string} the viewIdSuffix form the selected viewId
 */
export const getViewIdSuffix = (): string => {
  const viewId: string = SessionStore.get( ESessionStore.ViewId );
  const productId: string = getProductId();
  if ( viewId && viewId.length > 0 && productId ) {
    return viewId.slice( productId.length )
  }
  return ''
}

/**
 * @returns {string} the productId
 */
export const getProductId = (): string => {
  const productId: string = SessionStore.get( ESessionStore.ProductId )
  if ( productId && productId.length > 0 ) {
    return productId
  }
  return ''
}

export const createVariableAssignments = ( userAssignments: IUserAssignment[] ) => {
  //push MODEL_INCLUSION is assignments array with priority 999 for parent model
  const modelInclusion = userAssignments.find( a => a.variableId === ModelInclusion.Id );
  if ( !modelInclusion ) {
    const obj = { assignmentType: 'Singleton', variableId: ModelInclusion.Id, value: ModelInclusion.Included, exclude: false, priority: PriorityValue.High }
    userAssignments = [...userAssignments, obj];
  }
  return userAssignments.map( ( a: IUserAssignment ) => {
    let pr = a.priority && a.priority > 0 ? a.priority : undefined;
    if ( a.variableId.endsWith( IdFormat.Suffix.SubmodelId ) && Number( a.value ) >= 1 ) {
      pr = PriorityValue.High;
    }
    return { assignmentType: 'Singleton', variableId: a.variableId, value: a.value, exclude: false, priority: pr }
  } )
}

/**
 * To get list of conflicting values categorized into assignment and removal of the variable
 * @param {IConflictDialogConflictType} conflictVariable the varaible whose values are conflicting
 * @param {boolean} showCode the user preference for showCode
 * @returns {string[]} list of conflicting values categorized into assignment and removal of the variable
 */
export const getConflictsList = ( conflictVariable: IConflictDialogConflictType, showCode: boolean ) => {
  const assigned = conflictVariable.values.filter( ( value: IVariableValueAssignment ) => !value.exclude ).map( ( value: IVariableValueAssignment ) => getNameWithCode( value as IFeatureOrFamily, showCode ) ).join( ',' );
  const removed = conflictVariable.values.filter( ( value: IVariableValueAssignment ) => value.exclude ).map( ( value: IVariableValueAssignment ) => getNameWithCode( value as IFeatureOrFamily, showCode ) ).join( ',' );
  return removed.length ? [assigned, removed] : [assigned];
}

export const getLangAfterPrefChange = ( userPreferences, availableLanguages ) => {
  const langCode = userPreferences ? availableLanguages.filter( ( lang: INamed ) => {
    return lang.code === userPreferences.language || lang.name === userPreferences.language
  } ) : [];
  return langCode && langCode.length === 0 ? '' : langCode[0]['code'];
}

export const getLanguageCode = ( location?: string, isPrefLangChanged?: boolean ) => {
  const userSettings = AppStore.getState().userSettings;
  const userPreferences = AppStore.getState().userPreferences;
  const configuration = AppStore.getState().configuration;
  let langCode: INamed[];
  let availableLanguages = configuration['pmLanguages']
    ? configuration['pmLanguages'] : DefaultLanguage.Value;
  if ( location === EPageNames.LandingPage || location === EPageNames.Logout || location === EPageNames.CloseTab || location === EPageNames.Notified ) {
    availableLanguages = ApplicationSupportedLanguages
  }
  if ( !IsDefaultFlowInSessionStore() ) { //To handle when language is passed from down-stream application
    const urlLanguage = SessionStore.get( ESessionStore.Language );
    const urlCountry = SessionStore.get( ESessionStore.Country );
    const urlLanguageCode = urlLanguage + '-' + urlCountry; // Concatenate the language and country to get the locale code
    const isValidLanguage = Object.values( countryCodes ).map( x => x.locale ).includes( urlLanguageCode );
    const isUrlLanguageAvailable = availableLanguages.map( lang => lang.code ).includes( urlLanguageCode );
    if ( isValidLanguage && isUrlLanguageAvailable ) {
      return urlLanguageCode;
    }
  }
  langCode = userSettings ? availableLanguages.filter( ( lang: INamed ) => {
    return lang.code === userSettings.language || lang.name === userSettings.language
  } ) : [];
  if ( userPreferences.preferenceChanged && isPrefLangChanged ) {
    langCode = getLangAfterPrefChange( userPreferences, availableLanguages );
    return langCode;
  }
  return langCode && langCode.length === 0 ? DefaultLanguage.Id : langCode[0]['code'];
}

/**
 * To get CANBEOPTIONAL value of feature or family
 * @param {IConfigurationProperty} properties the properties of feature or family
 * @returns {boolean} CANBEOPTIONAL value
 */
export const canBeOptional = ( properties: IConfigurationProperty[] ) => {
  return properties?.find( ( property: IConfigurationProperty ) => property.id === EConfigItProperty.IsOptional )?.value
}

/**
 * To create family and feature concatinated id by "."
 * @param {featureId} featureId this is the id of feature in case of multiselect and select or family in case of string and numeric 
 * @param {variable} variable this is object of feature family
 * @returns {string} feature family id.feature id
 */
export const createId = ( featureId: string, variable: IConfigurationVariable ) => {
  return variable?.variableType === EVariableType.Multiselect || variable?.variableType === EVariableType.Singleselect ? variable.id + '.' + featureId : featureId;
}

/**
 * To create Feature id
 * @param {IValueType} value 
 * @param {IConfigurationVariable | null} variable 
 * @returns {string} Returns FeatureFamily id when it's a numeric or string family else returns value id
 */
export const getFeatureId = ( value: IValueType, variable: IConfigurationVariable | null ) => {
  return String( variable?.variableType === EVariableType.Multiselect || variable?.variableType === EVariableType.Singleselect ? value : variable?.id );
}


/**
 * To check feature or family id present in optionalItems array
 * @param {featureId} featureId this is the id of feature in case of multiselect and select or family in case of string and numeric 
 * @param {variable} variable this is object of feature family
 * @returns {boolean} true if id present on optionalItems array else false
 */
export const checkIsMarkedOptional = ( featureId: string, variable: IConfigurationVariable ) => {
  const id = createId( featureId, variable );
  const config = AppStore.getState().configuration as IExtendedConfigureResponse;
  return config.savedConfiguration.optionalItems?.has( id ) ? true : false;
}

/**
 * To check feature or family id present in optionalItems array
 * @param {featureId} featureId this is the id of feature in case of multiselect and select or family in case of string and numeric 
 * @param {value} value of checkbox true/false
 * @param {variable} variable this is object of feature family
 * @returns {Array} of optionalItems
 */
export const setOptional = ( featureId: string, value: boolean, variable: IConfigurationVariable ) => {
  const id = createId( featureId, variable );
  const config = AppStore.getState().configuration as IExtendedConfigureResponse;
  const optionalItems = config.savedConfiguration?.optionalItems ? structuredClone( config.savedConfiguration.optionalItems ) : new Map<string, boolean>();
  const flag = optionalItems.has( id );
  if ( !flag && value ) {
    optionalItems.set( id, true );
  } else if ( !value && flag ) {
    optionalItems.delete( id )
  }
  AppStore.dispatch( setOptionalData( { optionalItems: optionalItems } ) )
  return optionalItems;
}

/**
 * To check feature or family id present in optionalItems array
 * @param {id} id this is the id of feature in case of multiselect and select or family in case of string and numeric 
 * @param {variable} variable this is object of feature family
 * @returns {boolean} true if feature or family has value or else false
 */
export const checkFeatureSelected = ( id: string, variable: IConfigurationVariable ) => {
  const config = AppStore.getState().configuration as IExtendedConfigureResponse;
  let propName = 'value';
  if ( variable.variableType === EVariableType.Number || variable.variableType === EVariableType.String ) {
    propName = 'variableId';
  }
  const check = config.assignments?.find( f => f[propName as keyof IAssignment] === id && f.value );
  return check ? true : false;
}

/**
 * Returns the model and type of section represented by id
 * @param {string} sectionId the id of the section to be checked
 * @returns { Object } an object with the model and type parameters represented by the id
 */

export function getSectionInfo( sectionId?: string ) {
  if ( !sectionId ) {
    return { model: '', type: ESectionInfoType.Invalid }
  }
  const viewIdSuffix = getViewIdSuffix();
  const id = sectionId.split( '.' ).pop();
  if ( id?.match( IdFormat.Regex.ScopeSection ) ) {
    return { model: '', type: ESectionInfoType.Scope }
  }
  if ( id?.endsWith( viewIdSuffix ) ) {
    return { model: id?.replace( viewIdSuffix, '' ) || '', type: ESectionInfoType.Model };
  }
  if ( id?.startsWith( IdFormat.Suffix.SubmodelVariable ) ) {
    const match = id.match( /_\d+$/ );
    if ( match ) {
      return { model: id.slice( 1, match.index ), type: ESectionInfoType.Section };
    }
  }
  return { model: '', type: ESectionInfoType.Invalid }
}

/**
 * Checks if the given section is valid configurable section or not
 * @param {IContextData} contextData the context data required for performing the check
 * @param {ISection} section the section to be checked
 * @returns {boolean} whether a section is valid configurable section or not
 */

export function isValidConfigurableSection( contextData: IContextData | null, section: ISection ) {
  if ( !section.id || section.id?.endsWith( ProductSection.HiddenSection ) ) {
    return false;
  }
  let res = true;
  const models = contextData?.models;
  section.id.split( '.' ).forEach( ( idPart ) => {
    const info = getSectionInfo( idPart );
    if ( !( info.type === ESectionInfoType.Scope || models && info.model in models && info.type !== ESectionInfoType.Invalid ) ) {
      res = false;
    }
  } )
  return res;
}

const getDefaultCurrencyCode = ( countryCode: string, countryCurrency: { currency: string[] } ) => {
  const defaultCode = countryCurrency?.currency && countryCurrency?.currency.indexOf( 'USD' ) >= 0 ? 'USD' : countryCurrency?.currency[0];
  return countryCodes[countryCode]['currencyCode'] && countryCurrency?.currency.indexOf( countryCodes[countryCode]['currencyCode'] ) >= 0 ? countryCodes[countryCode]['currencyCode'] : defaultCode;
}

export const getCurrencyCode = ( countryCode: string, countryCurrency?: { currency: string[] } ) => {
  if ( !countryCurrency?.currency ) {
    return countryCodes[countryCode]['currencyCode'];
  }
  return countryCurrency?.currency.length === 1 ? countryCurrency?.currency[0] : getDefaultCurrencyCode( countryCode, countryCurrency );
}

export function getSymbol( countryCode: string, currencyCode: string ) {
  return ( 0 ).toLocaleString( countryCodes[countryCode]['locale'], { style: 'currency', currency: currencyCode, maximumFractionDigits: 0, currencyDisplay: 'narrowSymbol', useGrouping: false, signDisplay: 'never' } ).replace( /[\d\s\-.,]+/, '' )
}

export function checkIsOptionReadonly( value: IConfigurationValue | IConfigurationVariable ) {
  return value.properties?.find( ( property: IConfigurationProperty ) => property.id === EConfigItProperty.ReadOnly )?.value ? true : false;
}

export function checkIsOptionHidden( value: IConfigurationValue | IConfigurationVariable ) {
  return value.properties?.find( ( property: IConfigurationProperty ) => property.id === EConfigItProperty.Hide )?.value ? true : false;
}
export function checkIsOptionServiceRelevant( value: IConfigurationValue | IConfigurationVariable ) {
  return value.properties?.find( ( property: IConfigurationProperty ) => property.id === EPDMProperty.IsServiceRelevant )?.value ? true : false;
}

export function checkIsVariableSuppressed( value: IConfigurationVariable ) {
  return value?.suppressed ? true : false;
}

export function getFormattedPrice( price: number ) {
  const currency = JSON.parse( JSON.stringify( AppStore.getState().applicationSettings ) ).currency;
  return ' ' + price.toLocaleString( '' + currency.locale + '-u-nu-latn', { maximumFractionDigits: 2, minimumFractionDigits: 2 } );
}

export function getImages( variable: { id: string; value: any; } ) {
  const productImages = JSON.parse( JSON.stringify( AppStore.getState().configuration ) ).productImages;
  const id = variable.id ? variable.id?.split( '.' ).pop() : variable.value;
  return productImages[id] ? productImages[id].materialImages : null;
}


export function checkIfFeatureIsInValidByPhase( variable: IConfigurationVariable ) {
  switch ( variable.variableType ) {
    case EVariableType.String:
      return !variable.value && variable.values[0].state?.justification === EJustification.Phase;
    case EVariableType.Number:
    {
      const assignedValue = variable.values.find( value => value.state.isAssigned );
      return assignedValue?.value === 0 && assignedValue.state.justification === EJustification.Phase;
    }
    case EVariableType.Multiselect:
      return variable.values.length === 0 && variable.distinctValueCount === 0;
    default:
      return false;
  }
}

export function IsAppOpenedInIframe() {
  return window.location !== window.parent.location;
}

export function getScopeKey() {
  return UrlHelper.getSearchParameter( EUrlParams.Scope ) || SessionStore.get( ESessionStore.Scope );
}

export function IsAppOpenedByExtApp() {
  return SessionStore.get( ESessionStore.Token ) ? true : false;
}

export function showHeaderFooter() {
  return SessionStore.get( ESessionStore.HeaderLess ) ? JSON.parse( SessionStore.get( ESessionStore.HeaderLess ) ) : false;
}

export const hasOrderingInstruction = ( properties: IConfigurationProperty[] ) => {
  return properties?.find( ( property: IConfigurationProperty ) => property.id === EPDMProperty.OrderingInstructions )?.value
}

export const navigateToHome = ( navigateToHomeProps ) => {
  const { navigate } = navigateToHomeProps;

  AppStore.dispatch( resetConfiguration() );
  AppStore.dispatch( resetApplicationSettings() );
  AppStore.dispatch( resetMyConfigurations() );
  AppStore.dispatch( changeLandingTab( { value: true, tabValue: ETabValue.Product } ) );

  SessionStore.remove( ESessionStore.ViewId );
  SessionStore.remove( ESessionStore.PriceListMap );

  navigate( EPageNames.LandingPage, { replace: true } )
}


//This method returns the property from the configit properties
export function getAssociationPropertiesById( properties: IAssociationProperty[], id: string ) {
  return properties.find( property => property.code === id );
}

//This method returns the property from the association properties
export function getConfigurationPropertiesById( properties: IConfigurationProperty[], id: string ) {
  return properties.find( property => property.id === id );
}

//This method returns the property association for the material code
export function getPropertiesByMaterial( propertyAssociations: IPropertyAssociation[], materialCode: string ) {
  return propertyAssociations.find( propertyAssociation => propertyAssociation.materialCode === materialCode );
}


//This method return the configuration data when property association read
export function updateProperties( data: IConfiguration, propertyAssociations: IPropertyAssociation[], propertyDefinition: Array<IpropertyDefinition> ):IConfiguration {
  if( data ) {
    data.sections = addPdmProperties( data.sections, propertyAssociations, propertyDefinition );
  }
  return data;
}

//Update the variable and value properties to pdm propertiy values
export function addPdmProperties( sections: ISection[], propertyAssociations: IPropertyAssociation[], propertyDefinition: Array<IpropertyDefinition> ):ISection[] {
  if ( propertyAssociations.length > 0 ) {
    sections.forEach( section => {
      section.variables = updateVariableProperties( section.variables, propertyAssociations, propertyDefinition );
      section.sections = addPdmProperties( section.sections, propertyAssociations, propertyDefinition )
    } )
  }
  return sections;
}


/**
 * Update the  variable properties of the section
 * @param {IConfigurationVariable} variables  variable array
 * @param {IPropertyAssociation} propertyAssociations  property association list
 * @param {IpropertyDefinition} propertyDefinition  property definition list
 * @returns {IConfigurationVariable} returns the list of variable with updated 
 */
function updateVariableProperties ( variables: IConfigurationVariable[], propertyAssociations: IPropertyAssociation[], propertyDefinition: Array<IpropertyDefinition> ):IConfigurationVariable[] {
  let association;
  variables?.map( ( variable: IConfigurationVariable ) => {
    const id = getFeatureCode ( variable.id , variable.variableType );
    association = getPropertiesByMaterial( propertyAssociations, id );
    variable.properties = mapPdmProperties( variable?.properties, association?.properties, propertyDefinition );
    if ( variable?.values ) {
      updateValueProperties( variable?.values, propertyAssociations, propertyDefinition );
    }
  } )
  return variables
}


/**
 * Update the option properties of the feature families
 * @param {IConfigurationValue} values  values array
 * @param {IPropertyAssociation} propertyAssociations  property association list
 * @param {IpropertyDefinition} propertyDefinition  property definition list
 * @returns {IConfigurationValue} returns the list of variable
 */
function updateValueProperties ( values: IConfigurationValue[], propertyAssociations: IPropertyAssociation[], propertyDefinition: Array<IpropertyDefinition> ):void {
  let association;
  values?.forEach( ( value: IConfigurationValue ) => {
    const id = value?.value;
    association = getPropertiesByMaterial( propertyAssociations, id );
    value.properties = mapPdmProperties( value.properties, association?.properties, propertyDefinition );
  } )
}

/**
 * This function returns the property value from the property association
 * @param {IAssociationProperty} pdmProperty  pdm propeerty list
 * @param {string} type  property type
 * @returns {IValueType} returns the property value 
 */
const getValue = ( pdmProperty: IAssociationProperty, type:string ):IValueType => {
  let value = pdmProperty.value;
  if ( type === EPropertyType.Bool ) {
    value = String( pdmProperty?.value ).toLowerCase() === 'true' ? true : false;
  }

  return value;
}

//This function returns the default value from the property definition
const getDefaultValue = ( propertyDefinition: IpropertyDefinition |undefined ) => {
  let defaultValue = propertyDefinition?.default;
  if ( propertyDefinition?.type === EPropertyType.Bool ) {
    defaultValue = String( propertyDefinition?.default ).toLowerCase() === 'true' ? true : false;
  }

  return defaultValue;
}

//update properties array of variable and values
export function mapPdmProperties( configitProperties: IConfigurationProperty[], pdmProperties: IAssociationProperty[], propertyDefinition: Array<IpropertyDefinition> ) {
  if ( configitProperties ) {
    PdmProperties.forEach( ( property: string ) => {
      const pdmProperty = pdmProperties && pdmProperties.length > 0 && getAssociationPropertiesById( pdmProperties, property );
      const defautProperty: IpropertyDefinition | undefined = getDefaultProperty( propertyDefinition, property );
      if ( pdmProperty ) {
        const value = getValue( pdmProperty, defautProperty?.type );
        configitProperties = addPdmPropertyToConfigit( configitProperties, pdmProperty.code, value, defautProperty?.type )
      } else {        
        const defaultValue = getDefaultValue( defautProperty );
        if( defautProperty !== undefined ) {
          configitProperties = addPdmPropertyToConfigit( configitProperties, defautProperty.code, defaultValue, defautProperty?.type )
        }
      }
    } )
  }
  return configitProperties;
}

/**
 * This function add the pdm property to the existing configit properties array
 * @param {IConfigurationProperty } configitProperties  variable properties array
 * @param {stringtring} propertyCode  property code
 * @param {string} propertyValue  property value
 * @param {IValueType} propertyType  property type
 * @returns {IConfigurationProperty} returns the variable properties array with pdm properties
 */
export function addPdmPropertyToConfigit ( configitProperties: IConfigurationProperty[], propertyCode:string, propertyValue:IValueType, propertyType:string ): IConfigurationProperty[] {
  const hasPdmProperty = configitProperties.find( prop=>prop.id === propertyCode );
  if( hasPdmProperty ) {
    configitProperties.forEach( ( configProperty:IConfigurationProperty ) =>{
      if( configProperty.id === propertyCode ) {
        configProperty.value = propertyValue;
      }
    } )
  }else{
    configitProperties.push( { id: propertyCode, value: propertyValue, type: propertyType } )
  }
  return configitProperties;
}

/**
 * This method returns the  product level ORI Properties
 * @param {IOrderingInstructions} orderingInstructions  product orderingInstructions values
 * @param {IPropertyAssociation} propertyAssociations  propertyAssociations list
 * @param {String} modelId  product id
 * @returns {IOrderingInstructions} returns the ordering instructions
 */
export function updateProductORIProperties ( orderingInstructions: IOrderingInstructions, propertyAssociations: IPropertyAssociation[], modelId:string ):IOrderingInstructions {
  const updatedORI = {...orderingInstructions}
  if ( propertyAssociations.length > 0 ) {
    const product = getPropertiesByMaterial( propertyAssociations, modelId );
    if ( product ) {

      const hasORIProperty = getAssociationPropertiesById( product.properties, EPDMProperty.OrderingInstructions );
      if ( hasORIProperty ) {
        updatedORI[modelId] = { value: hasORIProperty.value }   
      }
    }
  }
  return updatedORI;
}

function checkAssignedByRule( configuration: IExtendedConfigureResponse, variable: IConfigurationVariable, isVariableSubmodel: boolean, lastChange: ILastChange ) {
  let newAssignment: IAssignment | null = null;
  configuration?.data?.sections.forEach( ( s ) => {
    s.variables.forEach( ( v: IConfigurationVariable ) => {
      if ( variable.id === v.id ) {
        v.values.forEach( ( val: IConfigurationValue ) => {
          if ( val.state.assigned === 'Rule' ) {
            lastChange.valueBefore = val.name;
            newAssignment = createNewAssignment( variable, configuration, isVariableSubmodel, '', 1 )
          }
        } )
      }
    } )
  } )
  return newAssignment;
}

// this method return the fallback Property to the configit properties array
export function getPdmProperty ( pdmProperty: IAssociationProperty ):IConfigurationProperty {
  return { id: pdmProperty.code, value: pdmProperty.value, type: pdmProperty.type }
}

//This function returns the response for success api calls
export function handleSuccess( response:AxiosResponse ) {
  return {
    data: response.data
  } 
}

//this method dispatch the api error to the error state
export function handleError( err: any, page?: string, data?: any ) {
  const errPayload = {
    code: err.response?.status || err.code,
    message: err.response?.data ? err.response.data.Message : err.message,
    page: page ? page : '',
    data: data ? data : null
  };
  AppStore.dispatch( setError( errPayload ) )

  return {
    response: err.response,
    error: errPayload,
    message: err.message,
    viewId: err?.response?.data,
  };
}

export function setClaimsData( action, state: IClaimsData ) {
  const featureFlagsTemp: IFeatureFlags = { ...state.featureFlags };
  const claimsData = { ...action?.claims }
  if ( claimsData && claimsData?.featureFlags ) {
    claimsData?.featureFlags.forEach( featureFlag => {
      if ( featureFlag.name in featureFlagsTemp ) {
        featureFlagsTemp[featureFlag.name] = { ...featureFlag, active: true }
      }
    } )
  }
  return { claims: claimsData, featureFlags: featureFlagsTemp }
}

//This function removes heirarchy and _[ViewId] from Product code 
export function removeViewSuffix( id: string ) {
  id = id.substring( id.lastIndexOf( '.' ) + 1 )
  return id.substring( 0, id.length - getViewIdSuffix().length )
}

export function disableActionButton( configuration:IExtendedConfigureResponse ) {
  return configuration.savedConfiguration?.state === EConfigurationType.Temp || configuration.savedConfiguration?.state === EConfigurationType.ReadOnly;
}

export function showConfigurationSummaryTabDrawer( applicationSettings: IApplicationSettings ) {
  return !applicationSettings.pageSize.isMedium && applicationSettings.showSummary || applicationSettings.pageSize.isMedium && applicationSettings.forceShowSummary;
}

//This method checks the compatible values are present in the variable values 
//If values exists returns true else returns false.
export function hasCompatibleValues ( variable: IConfigurationVariable ):boolean {
  if( variable.variableType !== EVariableType.Number && variable.variableType !== EVariableType.Submodel ) {
    return false;
  }
  return variable.values.filter( ( value: IConfigurationValue ) => !value.state.isAssigned && !value.state.isIncompatible ).length > 0;
}

// Check the Mandatory properties exist into the propertyDefinition
export const checkMandatoryProperties = ( propertyDefinition: Array<IpropertyDefinition> ): IMadatoryPDMAndConfItProperties => {
  const mandatoryProperties = ( mandatoryProperty: string ) => {
    return getDefaultProperty( propertyDefinition, mandatoryProperty )    
  }

  const configItMandatoryPropertyExit = ConfigitProperties.filter( mandatoryProperties );
  const mandatoryPropertyExits = PdmProperties.filter( mandatoryProperties );

  const pdmPropety = mandatoryPropertyExits.length === PdmProperties.length ? true : false;
  const configItPropery = configItMandatoryPropertyExit.length === ConfigitProperties.length ? true : false;

  return {configItPropertiesExist : configItPropery, pdmPropetyExist: pdmPropety};
}

// find the mandatory propert from the propertyDefinition
const getDefaultProperty = ( propertyDefinition: Array<IpropertyDefinition>, property: string ) : IpropertyDefinition | undefined => {
  return propertyDefinition?.find( ( propertyDef: IpropertyDefinition ) => propertyDef.code === property );  
}

//This function check the  RMA and PMA property for all the type of features
//Return the true or false based on the RMA and PMA property value for each feature
export function isFeatureValidByMarketAvailabilty( variable: IConfigurationVariable ): boolean {
  switch ( variable.variableType ) {
    case EVariableType.String:
    case EVariableType.Submodel:
    case EVariableType.Number:
      return isSupressFeatureOrOption( variable );
    case EVariableType.Singleselect:
    case EVariableType.Multiselect:
      return variable.values.length > 0 && variable.distinctValueCount > 0 && isSupressFeatureOrOption( variable ) && isOptionValidByMarketAvailability( variable );
    default:
      return true;
  }
}


//This function return true for false based on the PMA and RMA value of values
//Also set the suppressed value to all the value object
export function isOptionValidByMarketAvailability( variable:IConfigurationVariable ):boolean {
  let hasValueMarketAvailability = false;
  variable?.values.forEach( ( value: IConfigurationValue ) => {
    if ( isSupressFeatureOrOption( value ) ) {
      hasValueMarketAvailability = true
      value.suppressed = false
    } else {
      value.suppressed = true
    }
  } )
  return hasValueMarketAvailability;
}

//This funtion checks the RMA and PMA property value
//If both values are true then return true else return false
export function isSupressFeatureOrOption( variable: IConfigurationVariable | IConfigurationValue ):boolean {
  return getPropertyValue( variable, EConfigItProperty.ProcosMarketAvailable, EVariableType.Boolean ) && 
    getPropertyValue( variable, EConfigItProperty.RulesMarketAvailable, EVariableType.Boolean ) 
}

//This function returns the property value for the variable or value based on the type
export function getPropertyValue( variable: IConfigurationVariable | IConfigurationValue, property: string, type:string ): boolean {
  if ( type === EVariableType.Boolean ) {
    return variable.properties?.find( varProperty => varProperty.id === property )?.value ? true : false
  }
  return false;
}

//This function returns the initial tab index for models
export function getInitialTab( sections:ISection[] ):number {
  const initialTab = sections?.findIndex( s=>!s.suppressed )
  if( initialTab !== -1 ) {
    return initialTab;
  }
  return 0;
}

//This function returns the initial tab for submodels
export function getSubModelInitialTab ( configuration:IExtendedConfigureResponse, modelPath:string ):number {
  const config = JSON.parse( JSON.stringify( configuration ) );
  const {sections} = getSelectableSections( config, modelPath );
  return getInitialTab( sections );
}