import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../store';
import { Box, Dialog, DialogTitle, DialogContent, Button, FormControl, List, Select, MenuItem, Grid, Card, CardContent, TextField, Collapse, Alert, AlertTitle, Pagination, Stack, Autocomplete } from '@mui/material';
import { EConfigurationType, EUrlParams, ETabValue, RecordsPerPage, DefaultUserSettings, EFilterRootOptions } from '../data/Constants';
import { IProduct, ICreateConfigurationFormProps, ICreateConfigurationIdProps } from '../../types';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SimpleTreeView } from '@mui/x-tree-view';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import DraggableComponent from './DraggableComponent';
import { getUserDetails } from '../store/states/UserSettingsSlice';
import { checkIsSearchContent, getProductCatalog, getProductHierarchy, updateProductCatalogDetails } from '../store/states/ProductSettingsSlice';
import { setError } from '../store/states/ErrorSlice';
import { getUserPreferences, setUserPreferences } from '../store/states/UserPreferencesSlice';
import { changeLandingTab, createConfiguration, onLoadCheck } from '../store/states/ConfigurationSlice';
import { getClaims } from '../store/states/ClaimsDataSlice';

const configNameRegEx = /(^((\([A-Za-z0-9]+\))+|([A-Za-z0-9]+))+(((-|_| )[A-Za-z0-9]+)*(( |-|_)?(\([A-Za-z0-9]+\))+)*)*)$/


const loadProduct = async (loadProductProps) => {
  const { userSettings, token, productSettings, setSelected, setIsProductTabLoading, claimsData, dispatch } = loadProductProps;
  const { page, limit, agCodes, channelCodes } = productSettings.productCatalogDetails;
  if (Object.keys(userSettings?.details).length === 0) {
    await dispatch(getUserDetails({ token: token }));
    const userSettingsCheck = await dispatch(getUserPreferences({ token: token }));
    if (!userSettingsCheck) {
      dispatch(setUserPreferences({ ...DefaultUserSettings, isUserSettingsDefault: true }));
    }
  }
  if (!claimsData?.claims) {
    dispatch(getClaims({ token: token }));
  }
  setSelected(productSettings.productCatalogDetails?.agCodes ? productSettings.productCatalogDetails?.agCodes : [])
  if (Object.keys(productSettings.productCatalog).length === 0 || productSettings.isSearhContent) {
    dispatch(checkIsSearchContent({ flag: false }));
    await dispatch(getProductCatalog({ token: token, page: page, limit: limit, productSearch: '', agCodes: agCodes, channelCodes: channelCodes })).finally(() => {
      setIsProductTabLoading(prevVal => ({ ...prevVal, catalog: false }))
    });
    dispatch(getProductHierarchy({ token: token })).finally(() => {
      setIsProductTabLoading(prevVal => ({ ...prevVal, hierarchy: false }))
    });
  }
  //to not show loading text if catalog data is present when click on home button
  if (Object.keys(productSettings.productCatalog).length !== 0) {
    setIsProductTabLoading(prevVal => ({ ...prevVal, catalog: false, hierarchy: false }))
  }
  dispatch(onLoadCheck({ key: true }))
}

const handleRefresh = (refreshProps) => {
  const { token, recordsPerPage, setSelected, dispatch } = refreshProps;
  setSelected([]);
  dispatch(changeLandingTab({ value: true, tabValue: ETabValue.Product }))
  dispatch(getProductCatalog({ token: token, page: 1, limit: recordsPerPage }));
  dispatch(getProductHierarchy({ token: token }));
}

const handleChangeForRPP = (event: React.ChangeEvent<unknown>, paginationProps) => {
  const { token, configuration, selectedFilter, setRecordsPerPage, dispatch } = paginationProps
  if (event.target.name === 'recordsPerPage') {
    const records = event.target.value;
    setRecordsPerPage(records);
    dispatch(getProductCatalog({ token: token, page: 1, limit: records, productSearch: configuration.productSearchValue, agCodes: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }));
  }
}

const handlePaginationForCatalog = (catalogPaginationProps) => {
  const { token, value, configuration, selectedFilter, recordsPerPage, setCatalogPage, setProdList, productSettings, dispatch } = catalogPaginationProps;

  if (!productSettings.productCatalog[value]) {
    dispatch(getProductCatalog({ token: token, page: value, limit: recordsPerPage, productSearch: configuration.productSearchValue, agCodes: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }));
    setCatalogPage(value)
    dispatch(updateProductCatalogDetails({ page: value, limit: recordsPerPage, totalRecords: productSettings.productCatalogDetails.totalRecords, agCodes: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }))
  } else {
    setCatalogPage(value);
    dispatch(updateProductCatalogDetails({ page: value, limit: recordsPerPage, totalRecords: productSettings.productCatalogDetails.totalRecords, agCodes: selectedFilter.applicationIds, channelCodes: selectedFilter.channelCodes }))
    setProdList(productSettings.productCatalog[value])
  }
}

const setProdAndPageCatalogOnLoad = (pageDetailsProps) => {
  const { page, limit, totalRecords, setCatalogPage, setRecordsPerPage, setTotalCatalogRecords, setProdList, setProdHierarchy, productSettings } = pageDetailsProps;
  setCatalogPage(page);
  setRecordsPerPage(limit);
  setTotalCatalogRecords(Math.ceil(totalRecords / limit) || 1);
  setProdList(productSettings.productCatalog[page] || []);
  setProdHierarchy(productSettings.productHierarchy);
}

const formValidation = (formData: { name: string; countryCode: string; }, setDisplayNameErrorMessage: { (value: boolean): void; }, setDisableButton: { (value: boolean): void; }) => {
  const name = formData.name.trimEnd();
  const validName = name === '' || name.length > 2 && name.length <= 40 && configNameRegEx.test(name);
  const validCountry = formData.countryCode !== '';
  setDisplayNameErrorMessage(!validName);
  setDisableButton(!validName || !validCountry)
}

const createConfigurationId = (props: ICreateConfigurationIdProps) => {
  const { token, productId, closeDialog, countryCode, name, navigate, configuration, dispatch } = props;
  dispatch(createConfiguration({
    token: token,
    request: { productId: productId, country: countryCode, name: name.trim().length <= 0 ? null : name, fulfilmentDate: new Date(), state: EConfigurationType.Temp }
  },
  )).unwrap().then((res) => {
    if (res.error) {
      dispatch(setError(res.error));
    } else {
      dispatch(onLoadCheck({ key: false }));
      dispatch(checkIsSearchContent({ flag: configuration.productSearchValue ? true : false }));
      dispatch(changeLandingTab({ value: true, tabValue: ETabValue.Product }));
      navigate('/?' + EUrlParams.ConfigurationId + '=' + res.configurationId, { replace: true });
    }
  })
  closeDialog();
};

// Form for creating configuration
function CreateConfigurationForm(props: ICreateConfigurationFormProps) {
  const { countriesAvailable, closeDialog, createConfig, t } = props

  const [formData, setFormData] = useState({ name: '', countryCode: '' });
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (newVal: any) => {
    const name = 'countryCode'
    const value = newVal ? newVal.code : ''
    setFormData({ ...formData, [name]: value });
  }

  const handleNameChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value });
  }

  useEffect(() => {
    formValidation(formData, setDisplayNameErrorMessage, setDisableButton)
  })

  return <>
    <FormControl fullWidth className="form-control" size="small" >
      <Autocomplete
        className="countryCode"
        options={countriesAvailable}
        onChange={(_, newVal) => handleChange(newVal)}
        getOptionLabel={(option) => option ? option.name + ` (${option.code})` : ''}
        renderOption={(optionProps, option) =>
          <Box component="li" {...optionProps}>
            {option.name} ({option.code})
          </Box>
        }
        renderInput={(params) =>
          <TextField
            {...params}
            className="Country-Label"
            label={t('labels.country') + ' *'}
          />}
      />

    </FormControl><br /><br />

    <TextField
      fullWidth
      name="name"
      label={t('labels.configurationName')}
      placeholder={'(' + t('labels.optional') + ')'}
      size="small"
      value={formData.name}
      onChange={handleNameChange}
    /><br />
    <Collapse in={displayNameErrorMessage && formData.name.length > 0}>
      <Alert className="errorMessage" severity="error">
        <AlertTitle>{t('errorMessages.nameErrorMessage')} </AlertTitle>
      </Alert>
    </Collapse>
    <br />

    <div className="formActionButtons">
      <Button className="text-capitalize" onClick={closeDialog} data-testid="close-dialog">{t('button.cancel')}</Button>
      <Button className="text-capitalize" disabled={disableButton} onClick={() => createConfig(formData.name, formData.countryCode)} data-testid="create-configuration">{t('button.configure')}</Button>
    </div>

  </>
}

export const ProductPortfolio = (productPortfolioProps) => {
  const { prodHierarchy, setProdHierarchy, renderProductHierarchy } = productPortfolioProps;
  const auth = useAuth();
  const token = auth.userData?.access_token || '';
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const claimsData = useSelector((state: AppState) => state.claimsData)
  const userSettings = useSelector((state: AppState) => state.userSettings)
  const productSettings = useSelector((state: AppState) => state.productSettings);
  const configuration = useSelector((state: AppState) => state.configuration)

  const [productId, setProductId] = useState('');
  const [prodList, setProdList] = useState<IProduct[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogProdId, setDialogProdId] = useState('');
  const [dialogProdDesc, setDialogProdDesc] = useState('');
  const [countriesAvailable, setCountriesAvailable] = useState<IProduct['countries']>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedChannel, setSelectedChannel] = React.useState<string[]>([]);
  const [catalogPage, setCatalogPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(RecordsPerPage[0]); //Default records size is 15
  const [totalCatalogRecords, setTotalCatalogRecords] = useState<number>(1); //Default records size is 1
  const [isProductTabLoading, setIsProductTabLoading] = useState({ catalog: true, hierarchy: true });
  const [selectedFilter, setSelectedFilter] = useState<{ applicationIds: string[], channelCodes: string[], applicationNames: string[], channelNames: string[] }>({ applicationIds: [], channelCodes: [], applicationNames: [], channelNames: [] });
  const [expanded, setExpanded] = useState<string[]>([EFilterRootOptions.Channel]);
  const treeRootNodes = [EFilterRootOptions.Channel, EFilterRootOptions.Business];
  const showClearfilter: boolean = true;

  const openDialog = (prod: { productId: string, productDescription: string }) => {
    setDialogProdId(`${prod.productId}`);
    setDialogProdDesc(`${prod.productDescription}`);
    setShowDialog(true);
    setProductId(prod.productId);
  }

  const closeDialog = () => {
    setShowDialog(false);
  }

  function createConfig(name: string, countryCode: string) {
    createConfigurationId({ token, productId, closeDialog, countryCode, name, navigate, configuration, dispatch });
  }

  const handleChange = (_event: React.ChangeEvent<unknown>, value?: number) => {
    handlePaginationForCatalog({ token, value, configuration, selectedFilter, recordsPerPage, setCatalogPage, setProdList, productSettings, dispatch })
  }

  useEffect(() => {
    if (token) {
      loadProduct({ userSettings, token, catalogPage, recordsPerPage, productSettings, setSelected, setIsProductTabLoading, isProductTabLoading, configuration, claimsData, dispatch })
    }
  }, [token])

  useEffect(() => {
    const { page, limit, totalRecords } = productSettings.productCatalogDetails;
    setProdAndPageCatalogOnLoad({ page, limit, totalRecords, setCatalogPage, setRecordsPerPage, setTotalCatalogRecords, setProdList, setProdHierarchy, productSettings });
  }, [JSON.stringify(productSettings.productHierarchy), JSON.stringify(productSettings.productCatalog)])

  useEffect(() => {
    const productData: IProduct | undefined = prodList.find(x => {
      return x.productId === productId;
    })
    const countryData = productData ? productData.countries : [];
    setCountriesAvailable(countryData);
  }, [productId])

  return <Box className="product-portfolio-container">
    <Box className="hierarchy-container" style={{ marginTop: '25px' }}>
      <SimpleTreeView
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon
        }}
        onSelectedItemsChange={(event, nodeId) => {
          const index = expanded.indexOf(nodeId);
          const isRootNode = treeRootNodes.includes(nodeId);
          const copyExpanded = [...expanded];
          if (isRootNode) {
            if (index === -1) {
              copyExpanded.push(nodeId);
            } else {
              copyExpanded.splice(index, 1);
            }
            setExpanded(copyExpanded);
          }
        }}
        defaultExpandedItems={expanded}
        className="filter-treeview"
      >
        {isProductTabLoading.hierarchy ? <span className="noProduct-label">{t('landingPage.loading') + '...'}</span> :
          !isProductTabLoading.hierarchy && renderProductHierarchy(prodHierarchy, { selected, selectedChannel, configuration, setSelected, setProdList, setSelectedChannel, token, productSettings, selectedFilter, setSelectedFilter }, t, ETabValue.Product, dispatch, expanded, showClearfilter)}
      </SimpleTreeView>
    </Box>

    {!isProductTabLoading.catalog && prodList.length === 0 ? <span className="noProduct-label">{t('landingPage.noProductFound')}</span> :
      <Box className="list-Section-Tab list-Section-Tab::-webkit-scrollbar">
        <Box className="recordsChange-conatiner">
          <FormControl size="small" className="" style={{ width: '4.5rem', marginLeft: '5px' }}>
            <Select
              id="recordsPerPage"
              name="recordsPerPage"
              value={recordsPerPage}
              onChange={(e) => handleChangeForRPP(e, { token, configuration, selectedFilter, selectedChannel, setRecordsPerPage, dispatch })}
              className="recordsPerPage"
            >
              {RecordsPerPage.map(range => <MenuItem key={range} value={range}>{range}</MenuItem>)}
            </Select>
          </FormControl>
          <label>{t('labels.records')} :</label>
          <Button className="refresh-button common-btn" color="primary" variant="text" startIcon={<RefreshIcon />}
            onClick={() => {
              handleRefresh({ token, recordsPerPage, setSelected, dispatch })
            }}
          >
            {t('button.refresh')}
          </Button>
        </Box>
        <Box className="list-Section list-Section::-webkit-scrollbar">
          <List className="list-Items " >
            <Grid container spacing={1}
              className="list-ItemsContainer"
            >
              {prodList.map((prod: { productId: string, productDescription: string }, index: number) => {
                return <Grid key={index} item className="catalog-Container" >
                  <Card sx={{ border: '1px solid grey' }}>
                    <CardContent className="catalog-cardLayout">
                      <Box >
                        <Box>
                          <img src="public\png\SamplePMImage.png" className="catalog-image" />
                        </Box>

                        <Box className="catalog-info" >
                          <Box>
                            <span
                              key={index}
                              className="productCatalogCardHeader"
                            >{prod.productId}
                            </span>
                          </Box>
                          <Box className="catalog-productDescription">
                            <span
                              key={index}
                              className="text-capitalize productCatalogCardHeader"
                            >{prod.productDescription}
                            </span>
                          </Box>

                          <Box className="catalog-create-div" >
                            <Button className="text-capitalize catalog-create" variant="contained" onClick={() => openDialog(prod)}>{'+ ' + t('button.configure')}</Button>
                          </Box>

                        </Box>
                        <Box>  <StarOutlineIcon /> </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              })}
            </Grid>
          </List>
        </Box>

        <Stack spacing={1} className="catalog-pagination">
          <Pagination color="primary" count={totalCatalogRecords} page={catalogPage} onChange={handleChange} />
        </Stack>

      </Box>
    }

    <Dialog open={showDialog} PaperComponent={DraggableComponent} aria-labelledby="draggable-dialog-title" className="create-configuration-dialog common-dialog-style cursor-move">
      <DialogTitle className="header title">
        <b> {dialogProdId} </b>{` - ${dialogProdDesc}`}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '1em' }}>
        <CreateConfigurationForm countriesAvailable={countriesAvailable} closeDialog={closeDialog} createConfig={createConfig} t={t} />
      </DialogContent>
    </Dialog>

  </Box>
}