import React, { ReactElement, useState } from 'react';
import { IconButton, Tooltip, Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SaveIcon from '@mui/icons-material/Save';
import { Trans, useTranslation } from 'react-i18next';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
import ActionDialog from './ActionDialog';
import { GetToken, UrlHelper, disableActionButton, isConfigurationComplete } from '../services';
import { IExtendedConfigureResponse, ISection } from '../../types';
import { EActionType, ESessionStore, EUrlParams } from '../data/Constants';
import { SessionStore } from '../services/SessionStore';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/AppStore';
import { notify, setLoaderMessage } from '../store/states/ConfigurationSlice';
import { setIsConfigurationChanged } from '../store/states/ApplicationSettingsSlice';
import { setError } from '../store/states/ErrorSlice';
import { useNavigate } from 'react-router';


const handleNotifyResponse = ( res, setShowDialog: { ( value: React.SetStateAction<boolean> ): void}, handleSuccess , dispatch:AppDispatch ) => {
  if ( res.payload.status === 204 ) {
    dispatch( setError( { code: 204 } ) );
    setShowDialog( false )
    dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
  } else if ( res.payload.error ) {
    setShowDialog( false );
  } else {
    handleSuccess();
  }

}

const getAddProductButtonClass = ( disableButton:boolean ) => {
  return disableButton ? 'disable-product-action' : 'add-product-action'
}

const getSaveAndCloseIconClass = ( disableButton:boolean ) => {
  return disableButton ? 'btn-disabled icon-font-size' : 'notify-icon icon-font-size'
}

export const SaveAndClose = ( { configuration }: { configuration: IExtendedConfigureResponse } ) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState<boolean>( false );
  const [dialog, setDialog] = useState<string | ReactElement>( '' );
  const [errorMessage, setErrorMessage] = useState( '' );
  const [header, setHeader] = useState( '' );
  const [dialogType, setDialogType] = useState( '' );

  const dispatch = useDispatch<AppDispatch>();

  const disableButton = disableActionButton( configuration );
  const configurationId = UrlHelper.getSearchParameter( EUrlParams.ConfigurationId );
  const { token, auth } = GetToken();

  const onNotifyDialog = () => {
    //Check if the configuration is complete to proceed with Save and Notify.
    const isCompleteConfiguration = isConfigurationComplete( configuration?.data?.sections as ISection[] );
    if ( !isCompleteConfiguration ) {
      setDialogType( EActionType.IncompleteConfiguration );
      setHeader( t( 'incompleteConfigurationDialog.incompleteConfiguration' ) );
      setDialog( <Trans i18nKey="incompleteConfigurationDialog.incompleteConfigurationMessage" components={ { 1: <span style={ { color: 'red' } } /> } } /> );
    } else {
      setDialogType( EActionType.Notify );
      setHeader( t( 'closeWithSaveDialog.closeWithSaveChanges' ) );
      setDialog( t( 'closeWithSaveDialog.closeWithSaveConfirmationMessage' ) );
    }
    // To Reset Error Message while opening dialog
    setErrorMessage( '' );
    setShowDialog( true );
  }

  const onCloseDialog = () => {
    setDialog( '' );
    setShowDialog( false );
  }

  // Method to call the Notify API and handle error if Error Occurs
  const action = () => {
    if ( dialogType === EActionType.Notify ) {
      notifyAPICall()
    }
  }

  const notifyAPICall = () => {
    setShowDialog( false );
    setErrorMessage( '' );
    dispatch( setLoaderMessage( EActionType.Notify ) );
    dispatch( notify( { configurationId: SessionStore.get( ESessionStore.ConfigurationId ), token: token } ) ).then( ( res: any ) => {
      handleNotifyResponse( res, setShowDialog, handleSuccess, dispatch );
      window.parent.postMessage( { message: 'configuration_saved', cid: configurationId }, document.referrer );
    } )
  }

  const handleSuccess = () => {
    handleNotifySuccess();
    dispatch( setIsConfigurationChanged( { isConfigurationChanged: false } ) );
    dispatch( setLoaderMessage( null ) )
  }

  function handleNotifySuccess() {
    if ( IsDefaultFlowInSessionStore() ) {
      setDialogType( EActionType.Success );
      setDialog( t( 'successMessages.notifySuccess' ) );
      setShowDialog( true )
    } else {
      closeTab();
      setShowDialog( false )
    }
  }

  const closeTab = () => {
    onCloseDialog();
    if ( dialogType === EActionType.Notify ) {
      navigate( '/notified', { replace: true } );
    } else {
      navigate( '/closeTab', { replace: true } );
      window.parent.postMessage( { message: 'configuration_close', cid: configurationId }, document.referrer );
    }
    setTimeout( () => {
      auth?.signOut();
      SessionStore.clear()
    }, 2000 )
  }


  return (
    <>
      {!IsDefaultFlowInSessionStore() ?
        <Button variant="outlined" startIcon={ <AddShoppingCartIcon className="cart-icon" /> } onClick={ onNotifyDialog }
          disabled={ disableButton }
          data-testid="btn-save-close-configuration"
          className={ getAddProductButtonClass( disableButton ) }
        >
          <span>{t( 'labels.addProduct' )}</span>
        </Button>
        : <Tooltip title={ t( 'tooltip.saveAndClose' ) }>
          <span>
            <IconButton
              onClick={ onNotifyDialog }
              disabled={ disableButton }
              className={ `action-panel-buttons ${getSaveAndCloseIconClass( disableButton )}` }
              data-testid="btn-save-close-configuration"
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>}
      <ActionDialog showDialog={ showDialog } onCloseDialog={ onCloseDialog } header={ header } errorMessage={ errorMessage } dialog={ dialog } action={ action } dialogType={ dialogType } />
    </> )

}