import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { ESessionStore, ETabValue } from '../../data/Constants';
import { IConfigureRequest, IExtendedConfigureResponse, ILastChange, IMadatoryPDMAndConfItProperties } from '../../../types';
import { ConfigurationApi } from '../../api/ConfigurationApi';
import { SessionStore } from '../../services/SessionStore';
import { configured, getErrorData, getPriceData, loadedConfiguration, setProductImages, setSalesText } from '../Helpers/ConfigurationSliceHelper';
import { SalesTextApi } from '../../api/SalesTextApi';
import { PriceApi } from '../../api/PriceApi';
import { NotifyAPI } from '../../api/NotifyAPI';
import { ProductApi } from '../../api/ProductApi';
import { checkMandatoryProperties, updateProductORIProperties, updateProperties } from '../../services/ConfigurationDataHelperFunctions';
import { enableMapSet } from 'immer';
import { t } from 'i18next';

enableMapSet();

const initialState = {
  data: null,
  assignments: [],
  configurationId: null,
  languageCode: null,
  viewIds: null,
  lastChange: null,
  savedConfiguration: null,
  conflict: null,
  invalidView: null,
  isInvalid: null, //to check if configuration is invalid on first configure call
  errorCode: null,
  acceptedChanges: null,
  longSalesText: [],
  pmLanguages: null,
  authState: 'initial',
  infoDialogVariable: null,
  contextData: null,
  price: { prices: [] },
  currencyList: [],
  modelSalesTextData: new Map<string, boolean>(),
  modelPriceData: new Map<string, boolean>(),
  availableImagesData: new Map<string, boolean>(),
  bundledFeatures: {},
  shortSalesText: {},
  productSearchValue:'',
  onChangeProductSearchValue:'',
  loaderMessage: null,
  configureApiCall: false,
  productImages: {},
  access:'',
  onLoadCheck: false,
  checkLandingTab:{isTabChanged:false,tabValue:ETabValue.Product},
  orderingInstructions:{},
  propertyAssociations:[],
  isDataSet: true,
  propertyDefinition: [],
  propertyDefinitionMissingError: null,
  submodelPropertyAssociation: [],
  configitPropertyInEffect: true
} as unknown as IExtendedConfigureResponse

//Gets the views available for configuration id
export const getViewList = createAsyncThunk( 'configuration/getViewList', async ( action:{ configurationId:string, token: string } )=>{
  return ConfigurationApi.getViewList( action )
} );

//Loads the Configuration
export const loadConfiguration = createAsyncThunk( 'configuration/loadConfiguration', async ( action:{ configurationId: string, languageCode: string, token: string, viewId?: string }, thunkAPI )=>{
  thunkAPI.dispatch( loadConfig( action ) )
  return ConfigurationApi.loadConfiguration( action );
} );

//Configures the assignments and returns the Configure response
export const configure = createAsyncThunk( 'configuration/configure', async ( action:{ request: IConfigureRequest, token: string, language: string, lastChange?: ILastChange | null }, thunkAPI )=>{
  thunkAPI.dispatch( callConfigure( action ) )
  return ConfigurationApi.configure( action );
} );

//Returns the sales text based on apitype - short or long
export const getSalesText = createAsyncThunk( 'configuration/getSalesText', async ( action:{ language: string, token: string, modelId: string, apiType: string, showError?: boolean } )=>{
  return SalesTextApi.salesText( action );
} );

//Return the languages available for a product model
export const getPMLanguages = createAsyncThunk( 'configuration/getPMLanguages', async ( action:{ configurationId: string, token: string } )=>{
  return ConfigurationApi.getPMLanguages( action );
} );

//Returns the prices of Product model
export const getPrice = createAsyncThunk( 'configuration/getPrice', async ( action:{ token: string, countryCode: string, currencyCode: string, modelId?: string,priceOnChange?: boolean } )=>{
  return PriceApi.price( action )
} );

//Returns the currencies available for the market given for configuration
export const getCurrencyList = createAsyncThunk( 'configuration/getCurrencyList', async ( action:{ token: string, countryCode: string } )=>{
  return PriceApi.currency( action )
} );

//Clears the configuration 
export const clearConfiguration = createAsyncThunk( 'configuration/clearConfiguration', async ( action:{ configurationId: string, token: string, viewId: string } )=>{
  return ConfigurationApi.clearConfiguration( action )
} );

//Returns the price list map for the selected country/market for configuration
export const getPriceListMap = createAsyncThunk( 'configuration/getPriceListMap', async ( action:{ token: string } )=>{
  return PriceApi.priceListMap( action )
} );

//Creates a configuration
export const createConfiguration = createAsyncThunk( 'configuration/createConfiguration', async ( action:{ token: string, request: IConfigureRequest } )=>{
  return ConfigurationApi.createConfiguration( action )
} );

//Notify which is save and close
export const notify = createAsyncThunk( 'configuration/notify', async ( action:{configurationId: string, token: string} )=>{
  return NotifyAPI.notify( action )
} );

//Gets all the available images for the product model
export const getProductImages = createAsyncThunk( 'configuration/getProductImages', async ( action:{token: string, modelId: string} )=>{
  return ProductApi.getProductImages( action )
} );

//sets the optional items to db
export const setOptionalItems = createAsyncThunk( 'configuration/setOptionalItems', async ( action:{token:string,configurationId:string,optionalItems:string[]|null} )=>{
  return ConfigurationApi.setOptionalItems( action )
} );

//gets the property associations
export const getPropertyAssociations = createAsyncThunk( 'configuration/getPropertyAssociations', async ( action:{token:string, package: string, parentId: string, childId: string, propertyDefiniton: []} )=>{
  return ProductApi.getPropertyAssociations( action )
} );

const configurationSlice = createSlice( {
  name: 'configuration',
  initialState,
  reducers: {
    loadConfig( state, action ) {
      state.configurationId = action.payload.configurationId
      state.languageCode = action.payload.languageCode 
      state.isInvalid = null
      state.acceptedChanges = null
    },
    callConfigure( state, action ) {
      state.assignments = action.payload.request?.configureRequest?.line.variableAssignments;
      state.lastChange = action.payload.lastChange || null
    },
    clearConflict( state ) {
      state.conflict = null
    },
    validViewId( state ) {
      state.invalidView = false
    },
    setAuthState( state, action ) {
      state.authState = action.payload.authState
    },
    setInfoDialogVariable( state, action ) {
      state.infoDialogVariable = action.payload.infoDialogVariable
    },
    setModelSalesText( state, action ) {
      state.modelSalesTextData.set( action.payload.modelId, true );
    },
    setModelPrice( state, action ) {
      state.modelPriceData.set( action.payload.modelId, true )
    },
    resetModelSalesText( state ) {
      state.modelSalesTextData = initialState.modelSalesTextData;
    },
    resetModelPrice( state ) {
      state.modelPriceData = initialState.modelPriceData;
      state.price = {}
    },
    clearOptionalItem( state ) {
      if ( state.savedConfiguration ) {
        state.savedConfiguration.optionalItems = new Map<string, boolean>()
      }
    },
    resetShortSalesText( state ) {
      state.shortSalesText = initialState.shortSalesText
    },
    onLoadCheck( state, action ) {
      state.onLoadCheck = action.payload.key
    },
    setConfiguration( state, action ) { //Sets the updated configuration which includes properties to state
      state.isDataSet = false
      state.data = action.payload.data
      if( state.savedConfiguration ) {
        state.savedConfiguration.configureResponse = action.payload.data
      }else{
        state.savedConfiguration = {
          configureResponse: action.payload.data
        }
      }
      state.contextData = action.payload.contextData
    },
    setLoaderMessage( state, action ) {
      state.loaderMessage = action.payload
    },
    setConfigureAPI( state, action ) {
      state.configureApiCall = action.payload.value
    },
    resetConfiguration() {
      return initialState
    },
    updateProductSearchValue( state, action ) {
      state.productSearchValue = action.payload.productSearch
    },
    onChangeProductSearchValue( state, action ) {
      state.onChangeProductSearchValue = action.payload.productSearch
    },
    changeLandingTab( state, action ) {
      state.checkLandingTab = {
        isTabChanged: action.payload.value,
        tabValue: action.payload.tabValue
      }
    }, 
    setOptionalData( state, action ) {
      state.savedConfiguration.optionalItems = action.payload.optionalItems
    },
    setAvailableImagesData( state, action ) {
      state.availableImagesData.set( action.payload.modelId, action.payload.status );
    },
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getViewList.fulfilled, ( state, action ) => {
      if( action.payload.data ) {
        SessionStore.set( ESessionStore.ViewId, action.payload.data.length > 0 && action.payload.data[0] )
        state.viewIds = action.payload.data
      }
    } );
    builder.addCase( loadConfiguration.fulfilled, ( state, action ) => {
      if( action.payload?.data ) {
        const assigndData = loadedConfiguration( action.payload,state );
        state.data = assigndData.data
        state.assignments = assigndData.assignments
        state.access = assigndData.access
        state.contextData = assigndData.contextData
        state.conflict = assigndData.conflict
        state.isInvalid = assigndData.isInvalid
        state.acceptedChanges = assigndData.acceptedChanges
        state.savedConfiguration = assigndData.savedConfiguration
        state.isDataSet = true
      }
    } );
    builder.addCase( getSalesText.fulfilled, ( state, action ) => {
      state.isDataSet = true
      const salesText = setSalesText( action.payload, state );
      if( action.payload.apiType === 'short' ) {
        state.shortSalesText = salesText
      } else {
        state.longSalesText = salesText
      }
    } );
    builder.addCase( getPMLanguages.fulfilled, ( state, action ) => {
      if( action.payload?.pmLanguages ) {
        state.pmLanguages = action.payload?.pmLanguages[0].languages;
      }
    } );
    builder.addCase( getPrice.fulfilled, ( state, action ) => {
      if( action.payload?.price ) {
        const price = getPriceData( action.payload, state )
        state.price = price;
        state.isDataSet = true;
      }
    } );
    builder.addCase( getCurrencyList.fulfilled, ( state, action ) => {
      if( action.payload?.currency ) {
        state.currencyList = action.payload.currency
      }
    } );
    builder.addCase( getProductImages.fulfilled, ( state, action ) => {
      const images = setProductImages( action.payload, state )
      state.productImages = images
    } );
    builder.addCase( getPropertyAssociations.fulfilled, ( state, action ) => {
      
      if( action.payload?.propertiesAssociation ) {
        const {modelId, propertyDefinition, propertiesAssociation} = action.payload;
        const models = state.submodelPropertyAssociation;
        state.propertyAssociations = [...state.propertyAssociations,...propertiesAssociation]
        models.push( modelId );
        state.submodelPropertyAssociation = models;        

        state.propertyDefinition = propertyDefinition.length > 0 ? propertyDefinition : state.propertyDefinition;
        const mandatoryPropertyExist: IMadatoryPDMAndConfItProperties = checkMandatoryProperties( state.propertyDefinition );
        state.configitPropertyInEffect = mandatoryPropertyExist.configItPropertiesExist;

        state.orderingInstructions = updateProductORIProperties( state.orderingInstructions, state.propertyAssociations, modelId );
        
        if( mandatoryPropertyExist.pdmPropetyExist ) {
          state.data = updateProperties( state.data, state.propertyAssociations, state.propertyDefinition )
          state.propertyDefinitionMissingError = null;
        } else {
          state.propertyDefinitionMissingError = getErrorData( t );
        }
      } else if ( action.payload?.data[0]['modelId'] === action.payload?.data[0]['subModelId'] ) {        
        state.propertyDefinitionMissingError = getErrorData( t );
      }
    } );
    builder.addMatcher( isAnyOf( configure.fulfilled, clearConfiguration.fulfilled ), ( state, action ) => {
      if( action.payload?.configuration ) {
        state.isDataSet = true;
        const assigndData = configured( action.payload, state )
        state.conflict = assigndData?.conflict
        state.data = assigndData?.data
        state.assignments = assigndData?.assignments
        state.isInvalid = assigndData?.isInvalid === false || assigndData?.isInvalid ? assigndData.isInvalid : null
        state.acceptedChanges = assigndData?.acceptedChanges
        state.contextData = assigndData?.contextData
      }
    } );
  }
} )

export const { loadConfig, callConfigure, clearConflict, validViewId, setAuthState,
  setInfoDialogVariable, setModelSalesText, setModelPrice, resetModelSalesText, resetModelPrice,
  clearOptionalItem,resetShortSalesText, onLoadCheck, setLoaderMessage, setConfigureAPI, resetConfiguration,
  updateProductSearchValue, onChangeProductSearchValue, changeLandingTab, setConfiguration, setOptionalData,
  setAvailableImagesData
} = configurationSlice.actions;


export default configurationSlice.reducer
