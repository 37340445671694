import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { handleError, handleSuccess } from '../services/ConfigurationDataHelperFunctions';

export const UserSettingsApi = {
  getUsersettings: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/preferences/read',
      getHeaders( action.token )
      ).then( ( response ) => {
        return handleSuccess( response )
      } ).catch( () => null ) //If get User settings failed, default or fall back settings would be used
  },

  updateUsersettings: ( action: { token: string, request: any } ) => {
    return AxiosClient
      .post( '/user/v1/preferences/set',
        action.request,
        getHeaders( action.token ) 
      ).then( () => {
        return {
          data: action.request
        }
      } ).catch( ( err ) => handleError( err ) )
  },

  getUserDetails: ( action: { token: string } ) => {
    return AxiosClient
      .get( '/user/v1/details',
      getHeaders( action.token ) 
      ).then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}